import {
  CREATE_CONTENT_REQUEST,
  DELETE_CONTENT_REQUEST,
  UPDATE_CONTENT_REQUEST,
} from "../constant/types";

export const createContentRequest = (params) => ({
  type: CREATE_CONTENT_REQUEST,
  payload: { params },
});

export const updateContentRequest = (params) => ({
  type: UPDATE_CONTENT_REQUEST,
  payload: { params },
});

export const deleteContentRequest = (params) => ({
  type: DELETE_CONTENT_REQUEST,
  payload: { params },
});
