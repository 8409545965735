import { isEmpty } from "lodash";
import React from "react";
import { AiFillDelete } from "react-icons/ai";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import BaseView from "../../components/common/BaseView";
import SubCatCard from "../../components/home/SubCatCard";
import i18n from "../../locale/I18";
import { deleteFavoriteRequest } from "../../redux/action/favorite.action";
import {
  selectorCompletes,
  selectorFavorites,
} from "../../redux/store/selectors";

function FavoriteScreen(props) {
  const { favorites, deleteFavorite, completes } = props;
  const history = useHistory();

  const onClickSubCategory = (item, isComplete) => {
    history.push("tutorial", {
      params: {
        data: item,
        isComplete,
      },
    });
  };

  const onConfirmDeleteFavorite = (id) => {
    global.showConfirmAlert({
      title: i18n.t("Warning"),
      message: i18n.t("SureDeleteFavorite"),
      confirmText: i18n.t("Delete"),
      onConfirm: () => onDeleteFavorite(id),
    });
  };

  const onDeleteFavorite = (id) => {
    deleteFavorite({ id });
  };

  return (
    <BaseView>
      <div className="w-full h-full flex-col flex items-center justify-center relative">
        <div className="w-full flex justify-between items-center h-14 p-4 shadow-sm  bg-white">
          <p className="text-xl font-sans font-bold text-black">
            {i18n.t("Favorites")}
          </p>
        </div>
        <div className=" w-full flex flex-wrap items-center p-4 ">
          {!isEmpty(favorites) ? (
            favorites.map((item, index) => {
              const isComplete =
                completes?.findIndex((e) => e.categoryId == item.categoryId) !==
                -1;
              return (
                <SubCatCard
                  data={item.category}
                  isComplete={isComplete}
                  onClick={() => {
                    onClickSubCategory(item, isComplete);
                  }}
                  button={
                    <AiFillDelete
                      onClick={(e) => {
                        e.stopPropagation();
                        onConfirmDeleteFavorite(item.id);
                      }}
                      className="text-xl text-red-800 cursor-pointer z-20 hover:text-red-900 bg-white w-7 h-7 shadow-sm rounded-full p-1 absolute top-1 right-1"
                    />
                  }
                />
              );
            })
          ) : (
            <p className="text-gray-400 text-center font-bold text-2xl w-full">
              {i18n.t("NoFavoriteMassage")}
            </p>
          )}
        </div>
      </div>
    </BaseView>
  );
}

const mapStateToProps = (state) => ({
  favorites: selectorFavorites(state),
  completes: selectorCompletes(state),
});

const mapDispatchToProps = {
  deleteFavorite: deleteFavoriteRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteScreen);
