import React, { useEffect, useState } from "react";
import Avatar from "./Avatar";
import ChatComponent from "./ChatComponent";
import { MdClose } from "react-icons/md";
import i18n from "../../locale/I18";
import io from "socket.io-client";
import { GetMyChatsRooms } from "../../API/chat/Chat";
import { ServerAPI } from "../../global/Config";
import ReactTimeAgo from "react-time-ago";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import Spinner from "./Spinner";

TimeAgo.addDefaultLocale(en);

const socket = io(ServerAPI, {
  reconnection: false,
});

export default function ChatThreadComponent(props) {
  const { teacher, onClose, onBack, currentScreen, navigate, user } = props;
  const [chatThreads, setChatThreads] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    socket.on("onThreadChanged", chatThreadListener);
  }, [chatThreads]);

  console.log({ chatThreads });

  useEffect(() => {
    getChatTreads();
    connect();

    return () => {
      disconnect();
    };
  }, []);

  const getChatTreads = async () => {
    setLoading(true);
    const result = await GetMyChatsRooms(user?.accessToken);
    setChatThreads(result);
    setLoading(false);
  };

  const connect = () => {
    socket.connect();
    socket.emit("joinChatThread", () => {
      console.log("connected chat thread");
    });
  };

  const disconnect = () => {
    socket.disconnect();
    socket.off();
  };

  const chatThreadListener = (thread) => {
    const threadIndex = chatThreads.findIndex((e) => e.id == thread.id);
    if (threadIndex != -1) {
      chatThreads[threadIndex] = thread;
      const newThreads = chatThreads.sort((a, b) =>
        a.messages[0].createdAt > b.messages[0].createdAt ? -1 : 1
      );
      setChatThreads(newThreads);
    } else {
      chatThreads.unshift(thread);
      setChatThreads(chatThreads);
    }
  };

  const onClickThread = (item) => {
    console.log({ item });
    navigate({ name: "chat", student: item?.user, room: item?.id });
  };

  const renderThreads = () => {
    return (
      <div className="flex flex-col h-full w-full relative">
        <div className="flex items-center justify-between p-3 h-16 bg-primary-100 rounded-t-lg">
          <div className="flex items-center">
            <p className="font-semibold text-xl py-2">
              {i18n.t("RecentChats")}
            </p>
          </div>
          <MdClose
            size="1.5rem"
            onClick={onClose}
            className="cursor-pointer text-gray-500"
          />
        </div>
        <div className="overflow-y-auto w-full p-3 absolute bottom-0 right-0 top-16">
          {loading ? (
            <div className="flex flex-col justify-center items-center h-full">
              <Spinner className="text-gray-300" />
              <p>{i18n.t("PleaseWait")}</p>
            </div>
          ) : (
            chatThreads.map((item, index) => {
              const lastMessage = item.messages[0];
              return (
                <div
                  className="flex w-full pb-2 mb-2 border-b border-gray-200 items-center cursor-pointer"
                  onClick={() => onClickThread(item)}
                >
                  <Avatar src={item?.user?.photo} size="3.2rem" rounded />
                  <div className="flex flex-1 flex-col ml-2">
                    <p className="font-medium line-clamp-1">
                      {item?.user?.fullName}
                    </p>
                    <p className="text-xs text-gray-500 line-clamp-1">
                      {lastMessage.data}
                    </p>
                  </div>
                  <div className="">
                    <ReactTimeAgo
                      date={lastMessage?.createdAt}
                      locale="en-US"
                      className="text-xs text-gray-500"
                    />
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-1 flex-coll w-full">
      {currentScreen.name == "thread" ? (
        renderThreads()
      ) : (
        <ChatComponent
          onBack={onBack}
          onClose={onClose}
          teacher={teacher}
          user={user}
          student={currentScreen?.student}
          room={currentScreen?.room}
        />
      )}
    </div>
  );
}
