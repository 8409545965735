import axios from "axios";
import { ServerAPI, VERSION } from "../../global/Config";
import { HandleError } from "../../utils/error/Error";

const URL = `${ServerAPI}/${VERSION}/subscription`;

export const GetMySubscription = async (token) => {
  const context = `subscription @GetMySubscription`;
  try {
    const result = await axios.get(`${URL}/my`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (error) {
    HandleError(context, error);
  }
};
export const GetMyCurrentSubscription = async (token) => {
  const context = `subscription @GetMyCurrentSubscription`;
  try {
    const result = await axios.get(`${URL}/current`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (error) {
    HandleError(context, error);
  }
};
