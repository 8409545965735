import { Modal, Slide } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CustomButton from "../common/CustomButton";
import i18n from "../../locale/I18";
import TextInput from "../common/TextInput";

InputModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.bool.isRequired,
};

export default function InputModal(props) {
  const [text, setText] = useState("");
  const [firstNameText, setFirstNameText] = useState("");
  const [lastNameText, setlastNameText] = useState("");

  useEffect(() => {
    setText(props.text);
  }, [props.text]);

  useEffect(() => {
    setFirstNameText(props.text[0]);
    setlastNameText(props.text[1]);
  }, [props.name]);
  return (
    <Modal
      open={props.active}
      onClose={props.onClose}
      style={{ display: "flex", justifyContent: "center", alignItems: "center", outline: "none" }}
    >
      <Slide direction="up" in={props.active} mountOnEnter unmountOnExit>
        <div className="w-11/12 max-w-md bg-white rounded-lg p-4">
          <div>
            <p>{props.title}</p>
          </div>
          {props.name ? (
            <div>
              <TextInput
                onTextChange={(text) => setFirstNameText(text)}
                placeholder={i18n.t("FirstName")}
                value={firstNameText}
                size={"full"}
              />

              <TextInput
                onTextChange={(text) => setlastNameText(text)}
                placeholder={i18n.t("LastName")}
                value={lastNameText}
                size={"full"}
              />
            </div>
          ) : (
            <div>
              <TextInput
                onTextChange={(text) => setText(text)}
                placeholder={i18n.t("EnterYourInstagram")}
                value={text}
                size={"full"}
              />
            </div>
          )}
          <CustomButton
            onClick={() => {
              if (props.name) props.onSave([firstNameText, lastNameText]);
              else props.onSave(text);
              props.onClose();
            }}
            size="full"
            style="primary-light"
          >
            <p> {i18n.t("Save")}</p>
          </CustomButton>
        </div>
      </Slide>
    </Modal>
  );
}
