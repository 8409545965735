import { call, put, select } from "@redux-saga/core/effects";
import { get, isEmpty } from "lodash";
import {
  CreatePublicData,
  GetPublicData,
  UpdatePublicData,
} from "../../../API/publicData/PublicData";
import { getPublicDataResponse } from "../../action/general.action";
import { selectorPublicData, selectorUser } from "../../store/selectors";

export function* getPublicData() {
  let publicData = yield call(GetPublicData);
  console.log({ publicData });
  yield put(getPublicDataResponse(publicData));
}

export function* setPublicData(params) {
  params = get(params, "payload.params");
  console.log({ params });
  const user = yield select(selectorUser);
  const publicData = yield select(selectorPublicData);
  const { key, value } = params;
  let result = null;
  if (isEmpty(publicData[key])) {
    // should create a public data with key
    result = yield call(CreatePublicData, user?.accessToken, params);
  } else {
    //update public data with key
    result = yield call(UpdatePublicData, user?.accessToken, key, { value });
  }
  console.log({ result });
  if (result == "done") {
    publicData[key] = value;
    yield put(getPublicDataResponse(publicData));
  }
}
