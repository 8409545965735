import React from "react";
import PropTypes from "prop-types";
import { RiSearch2Line } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";

SearchInput.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onTextChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium", "large", "full"]),
  className: PropTypes.string,
};

export default function SearchInput(props) {
  const {
    onTextChange = () => {},
    disabled = false,
    value = "",
    size = "full",
    placeholder = "placeholder",
    className = "",
    onClear,
  } = props;
  return (
    <div
      className={`${className} inpt-${size} ${
        disabled ? "bg-gray-200" : "bg-gray-300 "
      } flex items-center rounded-md border-0 mb-2 mt-2`}
    >
      <input
        className={`w-full h-full outline-none text-black ${
          disabled ? "bg-gray-200" : "bg-gray-300 "
        }`}
        type={"text"}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onTextChange(e.target.value)}
        disabled={disabled}
        color="#f00"
      />

      <div className="flex items-center justify-center cursor-pointer mr-1 ml-1">
        {value == "" ? (
          <RiSearch2Line className="text-gray-500 text-xl" />
        ) : (
          <IoMdClose className="text-gray-500 text-xl" onClick={onClear} />
        )}
      </div>
    </div>
  );
}
