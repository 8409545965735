import React, { useEffect, useState } from "react";
import BaseView from "../../components/common/BaseView";
import TextInput from "../../components/common/TextInput";
import i18n from "../../locale/I18";
import { AiOutlineSend } from "react-icons/ai";
import {
  selectorTeacher,
  selectorUser,
  selectorWorkshopsContent,
} from "../../redux/store/selectors";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import moment from "moment";
import { MessageList } from "react-chat-elements";
import { Input } from "react-chat-elements";
import { setMessageRequest } from "../../redux/action/chat.action";

function ChatScreen(props) {
  //   const { setMessage } = props;
  //   const [text, setText] = useState("");
  //   const [messages, setMessages] = useState([]);
  //   const location = useLocation();
  //   // const from = props?.route?.params?.from;
  //   const user = props?.user;
  //   const teacherPic = props?.teacher?.photo;
  //   const teacherID = props?.teacher?.id;
  //   const teacherFCM = props?.teacher?.fcm || "fcm";
  //   const teacherName = `${props?.teacher?.firstName} ${props?.teacher?.lastName}`;
  //   const student = location.state?.student || {};
  //   let key;
  //   if (isEmpty(student)) {
  //     key = `${props?.teacher?.id}___${CurrentUser().uid}`;
  //   } else {
  //     key = `${props?.teacher?.id}___${student._id}`;
  //   }
  //   let inputRef = React.createRef();
  //   console.log({ key });
  //   const getMessages = async () => {
  //     const response = await GetChatMessages({
  //       key,
  //     });
  //     let tempMessages = [];
  //     if (response) {
  //       Object.keys(response).map((key) => {
  //         response[key].position = response[key]?.senderID == CurrentUser().uid ? "right" : "left";
  //         response[key].date = moment(response[key]?.createdAt).toISOString();
  //         console.log({ date: moment(response[key]?.createdAt).toISOString() });
  //         tempMessages = [response[key], ...tempMessages];
  //       });
  //       setMessages([...tempMessages]);
  //     } else {
  //       setMessages([
  //         {
  //           _id: 1,
  //           text: i18n.t("HaveQuestionDesc"),
  //           type: "text",
  //           position: "left",
  //           createdAt: moment().format(),
  //           date: new Date(),
  //           user: {
  //             _id: teacherID,
  //             name: teacherName,
  //             avatar: teacherPic,
  //           },
  //         },
  //       ]);
  //     }
  //   };
  //   const chatListener = () => {
  //     const dbRef = ref(database);
  //     const messagesRef = child(dbRef, `/chats/${key}/messages`);
  //     onValue(messagesRef, (snapshot) => {
  //       const data = snapshot.val();
  //       console.log({ data });
  //       let tempMessages = [];
  //       Object.keys(data).map((key) => {
  //         data[key].position = data[key]?.senderID == CurrentUser().uid ? "right" : "left";
  //         data[key].date = moment(data[key]?.createdAt).toISOString();
  //         tempMessages = [data[key], ...tempMessages];
  //       });
  //       setMessages([...tempMessages]);
  //       // setMessages(data);
  //     });
  //   };
  //   useEffect(() => {
  //     getMessages();
  //     // ChatListener({ key });
  //     chatListener();
  //     return () => {
  //       // RemoveChatListener({ key });
  //     };
  //   }, []);
  //   const onSend = () => {
  //     const messageObj = {
  //       message: {
  //         text: text,
  //         type: "text",
  //         senderID: CurrentUser().uid,
  //         receiverID: !isEmpty(student) ? student._id : teacherID,
  //         seen: false,
  //         delete: false,
  //         createdAt: moment().format(),
  //         user: {
  //           _id: CurrentUser().uid,
  //           name: `${user.firstName} ${user.lastName}`,
  //           avatar: user.photo,
  //         },
  //       },
  //       user: {
  //         _id: CurrentUser().uid,
  //         name: `${user.firstName} ${user.lastName}`,
  //         avatar: user.photo,
  //         fcm: user.fcm,
  //       },
  //       receiverFCM: !isEmpty(student) ? student.fcm : teacherFCM,
  //       teacher: props?.teacher?.id === CurrentUser().uid,
  //       key,
  //     };
  //     console.log({ messageObj });
  //     setMessage(messageObj);
  //     const localMessageObj = {
  //       text,
  //       type: "text",
  //       position: "right",
  //       date: moment().format(),
  //       user: {
  //         _id: user.id,
  //         name: `${user.firstName} ${user.lastName}`,
  //         avatar: user.photo,
  //       },
  //     };
  //     // setMessages([...messages, localMessageObj]);
  //     setText("");
  //     inputRef.clear();
  //   };
  //   console.log({ text });
  //   return (
  //     <BaseView>
  //       <div className="w-full h-full flex-col flex relative">
  //         <div className="w-full h-full my-4">
  //           <MessageList
  //             className="message-list"
  //             lockable={true}
  //             toBottomHeight={"100%"}
  //             dataSource={messages}
  //           />
  //         </div>
  //         <div className="h-16 w-full max-w-5xl fixed md:bottom-2 bottom-14 flex items-end p-2">
  //           <Input
  //             ref={(el) => (inputRef = el)}
  //             placeholder={i18n.t("TextPlaceholder")}
  //             maxHeight={150}
  //             inputStyle={{ height: 50 }}
  //             onChange={(e) => {
  //               const text = e.target.value;
  //               setText(text);
  //             }}
  //             multiline={true}
  //             rightButtons={
  //               text != "" && (
  //                 <div
  //                   onClick={onSend}
  //                   className="rounded-full w-12 h-12 bg-green-500 hover:bg-green-700 flex items-center justify-center ml-2 cursor-pointer"
  //                 >
  //                   <AiOutlineSend className="text-white text-xl" />
  //                 </div>
  //               )
  //             }
  //           />
  //           {/* <TextInput
  //             value={text}
  //             onTextChange={(text) => setText(text)}
  //             placeholder={i18n.t("TextPlaceholder")}
  //             size={"full"}
  //           />
  //           <div className="rounded-full w-12 h-12 bg-green-500 flex items-center justify-center ml-2 cursor-pointer">
  //             <AiOutlineSend className="text-white text-xl" />
  //           </div> */}
  //         </div>
  //       </div>
  //     </BaseView>
  //   );
}

const mapStateToProps = (state) => ({
  chatContent: selectorWorkshopsContent(state),
  teacher: selectorTeacher(state),
  user: selectorUser(state),
});

const mapDispatchToProps = {
  setMessage: setMessageRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatScreen);
