import { get } from "lodash";
import { call, put, select } from "redux-saga/effects";
import { GetTeacher, UpdateUser } from "../../../API/user/User";
import {
  getTeacherDataResponse,
  getUserDataResponse,
} from "../../action/user.action";
import { selectorUser } from "../../store/selectors";

export function* setUser(params) {
  params = get(params, "payload.params");
  console.log({ params });
  const user = yield select(selectorUser);
  const result = yield call(UpdateUser, user?.accessToken, params);
  console.log({ result });
  if (result == "done") {
    const newUserData = { ...user?.userData, ...params };
    user.userData = newUserData;
    yield put(getUserDataResponse(user));
    global.showToast({
      duration: 3000,
      message: "ProfileUpdated",
      variant: "success",
    });
  }
}

export function* removeUser() {
  yield put(getUserDataResponse({}));
}

export function* getTeacher() {
  const user = yield select(selectorUser);
  const teacherResponse = yield call(GetTeacher, user?.accessToken);
  console.log({ teacherResponse });
  yield put(getTeacherDataResponse(teacherResponse));
}

export function* checkFCM() {}
