import {GET_CLASSES_CONTENT_RESPONSE} from '../constant/types';

const initialState = {
  classesContent: {
    cats: [],
    subCats: [],
    searchWrap: [],
  },
};

export default (state = initialState, action = {}) => {
  const {payload, type} = action;
  switch (type) {
    // Manage setIsLoadingRequest action to change loading status into store
    case GET_CLASSES_CONTENT_RESPONSE:
      return {
        ...state,
        classesContent: {...payload.params},
      };

    default:
      return state;
  }
};
