import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Spinner from "../../components/common/Spinner";
import { CodeGenerator } from "../../global/GeneralFunctions";
import i18n from "../../locale/I18";
import OtpInput from "react-otp-input";
import CustomButton from "../../components/common/CustomButton";

function EmailOTPScreen() {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const timout = 10;
  const [enteredCode, setEnteredCode] = useState("");
  const [timer, setTimer] = useState(timout);
  let timerKey = null;
  const location = useLocation();

  useEffect(() => {
    sendOTP();
    return () => {
      clearTimeout(timerKey);
    };
  }, []);

  //useEffect for timer
  useEffect(() => {
    setTimerInterval();
    return () => {
      clearTimeout(timerKey);
    };
  }, [timer]);

  const setTimerInterval = () => {
    timerKey = setTimeout(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);
  };

  const sendOTP = async () => {
    //send code
    const genCode = CodeGenerator(6);
    setCode(genCode);
    // const response = await EmailVerification({
    //   email,
    //   code,
    //   fullName: `${firstName} ${lastName}`,
    // });
    //setTimer
    setTimer(timout);
  };

  const onSubmit = () => {};

  return (
    <div className="primary-light h-full flex justify-center items-center">
      <div className="rounded-lg max-w-4xl relative bg-white shadow-lg flex flex-col items-center w-10/12 p-4">
        <p className="text-black text-left w-full text-2xl mb-6">
          {i18n.t("EmailVerificationTitle")}
        </p>
        <p className="text-black text-left w-full text-lg mb-6">
          {i18n.t("EmailVerificationDesc")}
        </p>
        <div className="mt-10 mb-10 flex flex-col justify-s items-center">
          <OtpInput
            containerStyle={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              marginBottom: "4rem",
            }}
            inputStyle={{
              width: "3rem",
              height: "3rem",
              margin: "10px 10px",
              borderRadius: "8px",
              borderColor: "#EFEFEF",
              borderWidth: "2px",
              fontSize: "1.7rem",
            }}
            value={enteredCode}
            onChange={(code) => {
              setEnteredCode(code);
            }}
            numInputs={6}
            separator={<span> </span>}
          />
          <p
            className={`text-black text-center text-xl ${
              timer == 0 && "cursor-pointer text-primary"
            } `}
            onClick={() => {
              if (timer == 0) {
                sendOTP();
              }
            }}
          >
            {timer == 0
              ? i18n.t("Resend")
              : `00:${timer.toString().padStart(2, "0")}`}
          </p>
        </div>
        <CustomButton
          loading={loading}
          onClick={onSubmit}
          className="mb-4 mt-8"
          size="full"
          style="primary-light"
        >
          <p>{i18n.t("Submit")}</p>
        </CustomButton>
      </div>
    </div>
  );
}

export default EmailOTPScreen;
