import { Modal, Slide } from "@mui/material";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import i18n from "../../locale/I18";
import Avatar from "../common/Avatar";
import CustomButton from "../common/CustomButton";
import TextInput from "../common/TextInput";
import NoImage from "../../assets/image/home/no_img.jpg";

export default function SocialMediaModal(props) {
  const { active, data, onClose, onSubmit } = props;
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [icon, setIcon] = useState("");
  const [index, setIndex] = useState(null);
  const [pickedFile, setPickedFile] = useState(null);

  useEffect(() => {
    if (!isEmpty(data)) {
      setLink(data.link);
      setTitle(data.title);
      setIcon(data.icon);
      setIndex(data.index);
    }
  }, [data]);

  const close = () => {
    setIcon("");
    setTitle("");
    setLink("");
    setIndex(null);
    setPickedFile(null);
    onClose();
  };

  const submit = () => {
    const params = { icon, title, link, index, pickedFile };
    onSubmit(params);
    close();
  };

  const onChooseIcon = async () => {
    var input = document.createElement("input");
    input.type = "file";
    input.accept = "image/png, image/jpeg, image/jpg";
    input.click();
    input.addEventListener("input", chooseIcon);
  };

  const chooseIcon = async (e) => {
    const file = e.target.files[0];
    setPickedFile({
      file,
      path: URL.createObjectURL(file),
      mime: file.type,
    });
  };

  const icn = pickedFile ? pickedFile.path : data?.icon;

  let buttonDisable = true;
  if (!isEmpty(data)) {
    if (
      icon != data.icon ||
      title != data.title ||
      link != data.link ||
      !isEmpty(pickedFile)
    )
      buttonDisable = false;
  } else {
    if (!isEmpty(pickedFile) && title != "" && link != "")
      buttonDisable = false;
  }

  return (
    <Modal
      open={active}
      onClose={close}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
      }}
    >
      <Slide direction="up" in={active} mountOnEnter unmountOnExit>
        <div className="bg-white w-full max-w-2xl h-5/6 rounded-t-lg border-none outline-none shadow-lg flex flex-col">
          <div className="w-full h-12 p-3 border-b-2 border-gray-600 flex items-center justify-center rounded-t-lg">
            <p>
              {isEmpty(data)
                ? i18n.t("AddSocialMedia")
                : i18n.t("EditSocialMedia")}
            </p>
          </div>
          <div className="flex flex-col flex-1  p-3">
            <div className="flex items-center justify-center mb-4">
              <Avatar src={icn} size="10rem" rounded placeholder={NoImage} />
              <CustomButton
                onClick={onChooseIcon}
                size="small"
                style="primary"
                className="ml-4"
              >
                <p>{i18n.t("ChooseIcon")}</p>
              </CustomButton>
            </div>
            <p>{i18n.t("Title")}</p>
            <TextInput
              value={title}
              onTextChange={(text) => setTitle(text)}
              placeholder=""
              size="full"
              className="w-full mb-4"
            />
            <p>{i18n.t("Link")}</p>
            <TextInput
              value={link}
              onTextChange={(text) => setLink(text)}
              placeholder=""
              size="full"
              className="w-full mb-4"
            />
          </div>
          <div className="p-4 flex justify-self-end">
            <CustomButton
              disabled={buttonDisable}
              onClick={submit}
              size="full"
              style="primary-light"
            >
              <p>{isEmpty(data) ? i18n.t("Add") : i18n.t("Edit")}</p>
            </CustomButton>
          </div>
        </div>
      </Slide>
    </Modal>
  );
}
