import React from "react";
import avatar from "../../assets/image/profile/avatar.png";
import { BiImageAdd } from "react-icons/bi";

export default function Avatar(props) {
  const { size, onChange, src, rounded, className, placeholder } = props;

  const img = !!src ? src : placeholder ? placeholder : avatar;

  return (
    <div
      className={`${rounded ? "rounded-full" : "rounded-lg"} relative`}
      style={{
        width: size,
        height: size,
      }}
    >
      <img
        src={img}
        onClick={onChange}
        style={{
          width: size,
          height: size,
        }}
        className={`object-cover ${rounded ? "rounded-full" : "rounded-lg"} ${
          onChange ? "cursor-pointer" : ""
        } ${className}`}
      />

      {onChange && (
        <div
          className={`flex w-5 h-5 bg-white  rounded-full shadow-lg justify-center items-center absolute  right-1 bottom-1 cursor-pointer`}
        >
          <BiImageAdd className="w-4 h-4" onClick={onChange} />
        </div>
      )}
    </div>
  );
}
