import { Modal, Rating, Slide } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import i18n from "../../locale/I18";
import {
  selectorCompletes,
  selectorFavorites,
  selectorIsTeacherOrAdmin,
  selectorPublicData,
  selectorTeacher,
  selectorUser,
} from "../../redux/store/selectors";
import Colors from "../../utils/style/Colors";
import CustomButton from "../common/CustomButton";
import { FiInstagram } from "react-icons/fi";
import {
  createFavoriteRequest,
  deleteFavoriteRequest,
} from "../../redux/action/favorite.action";
import { setCompleteRequest } from "../../redux/action/complete.action";
import { TiStarFullOutline, TiStarOutline } from "react-icons/ti";
import Avatar from "../common/Avatar";
import TextAreaInput from "../common/TextAreaInput";
import { CreateReview } from "../../API/review/Review";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";

function ReviewModal(props) {
  const {
    active,
    onClose,
    publicData,
    teacher,
    categoryId,
    user,
    deleteFavorite,
    createFavorite,
    favorites,
    setComplete,
  } = props;
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [favorite, setFavorite] = useState(false);
  const history = useHistory();

  useEffect(() => {
    checkFavorite();
  }, []);

  const checkFavorite = () => {
    const isFavorite =
      favorites.findIndex((e) => e.categoryId == categoryId) != -1;
    setFavorite(isFavorite);
  };

  const toggleFavorite = async () => {
    if (favorite) {
      //delete favorite
      const id = favorites.find((e) => e.categoryId == categoryId).id;
      await deleteFavorite({ id });
      setFavorite(false);
    } else {
      //create favorite
      await createFavorite({ categoryId });
      setFavorite(true);
    }
  };

  const openTeacherInstagram = () => {
    let address = publicData?.socialMedia?.find(
      (e) => e.title == "instagram"
    )?.link;
    window.open(address, "_blank");
  };

  const onSubmit = async () => {
    const result = await CreateReview(user?.accessToken, {
      teacherId: teacher.id,
      categoryId,
      rating,
      review,
    });
    if (!isEmpty(result)) {
      global.showToast({
        duration: 3000,
        message: "ThanksForSubmitReview",
        variant: "success",
      });
      await setComplete({
        categoryId,
      });
      history.push("/");
    }
  };

  let disableButton = true;
  if (rating > 0 && review.trim() != "") disableButton = false;
  return (
    <Modal
      open={active}
      onClose={onClose}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Slide direction="up" in={active} mountOnEnter unmountOnExit>
        <div className="w-full p-6 rounded-lg max-w-xl bg-white relative outline-none border-none flex flex-col items-center">
          <div className="w-full flex items-center justify-end">
            {favorite ? (
              <TiStarFullOutline
                size="1.5rem"
                className="cursor-pointer"
                onClick={toggleFavorite}
              />
            ) : (
              <TiStarOutline
                size="1.5rem"
                className="cursor-pointer"
                onClick={toggleFavorite}
              />
            )}
          </div>
          <div className="flex flex-col items-center w-full">
            <Avatar
              src={teacher?.photo}
              size="7rem"
              rounded
              className=" -mt-6 shadow-md"
            />

            <Rating
              className="mb-4"
              name="read-only"
              value={rating}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
              style={{ color: Colors.ratingFill }}
            />
            <TextAreaInput
              className="mx-4 mb-6"
              placeholder={i18n.t("LeaveMeReview")}
              value={review}
              onTextChange={(text) => setReview(text)}
            />
            <div
              onClick={() => openTeacherInstagram()}
              className="rounded-lg bg-primary-900 p-3 flex items-center justify-between cursor-pointer mb-4"
            >
              <FiInstagram className="mr-2 text-xl text-white" />
              <p className="text-white">{i18n.t("ShareWithMeOnIstagram")}</p>
            </div>
          </div>

          <CustomButton
            onClick={onSubmit}
            size="full"
            style="primary"
            disabled={disableButton}
          >
            <p>{i18n.t("Submit")}</p>
          </CustomButton>
        </div>
      </Slide>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  favorites: selectorFavorites(state),
  user: selectorUser(state),
  teacher: selectorTeacher(state),
  publicData: selectorPublicData(state),
});

const mapDispatchToProps = {
  createFavorite: createFavoriteRequest,
  deleteFavorite: deleteFavoriteRequest,
  setComplete: setCompleteRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewModal);
