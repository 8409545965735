import React, { useEffect, useState } from "react";
import i18n from "../../locale/I18";
import NoImage from "../../assets/image/home/no_img.jpg";
import { isEmpty } from "lodash";
import { Modal, Slide } from "@mui/material";
import CustomButton from "../common/CustomButton";
import TextInput from "../common/TextInput";
import TextAreaInput from "../common/TextAreaInput";

export default function CategoryModal(props) {
  const [title, setTitle] = useState("");
  const [pickedImage, setPickedImage] = useState(null);
  const [noSubCats, setNoSubCats] = useState(false);
  const { active, type, data, onClose, onSave, categoryId } = props;

  useEffect(() => {
    if (!isEmpty(data)) {
      setTitle(data.title);
      setNoSubCats(!isEmpty(data.contents));
    }
  }, [data]);

  const onChooseImage = async () => {
    var input = document.createElement("input");
    input.type = "file";
    input.accept = "image/png, image/jpeg, image/jpg";
    input.click();
    input.addEventListener("input", setImage);
  };

  const setImage = (e) => {
    const image = e.target.files[0];
    const url = URL.createObjectURL(image);
    setPickedImage({ path: url, mime: image.type, file: image });
  };

  const close = () => {
    setTitle("");
    setPickedImage(null);
    setNoSubCats(false);
    onClose();
  };

  const onSubmit = () => {
    let obj = {
      ...data,
      type,
      categoryId,
      title,
      pickedImage,
      noSubCats,
    };
    onSave(obj);
    close();
  };

  const confirmDiscard = () => {
    global.showConfirmAlert({
      title: i18n.t("Warning"),
      message: i18n.t("SureDiscardChanges"),
      confirmText: i18n.t("Discard"),
      onConfirm: () => {
        close();
      },
    });
  };

  let img = pickedImage ? pickedImage.path : data?.image ? data.image : NoImage;
  let buttonDisable = true;
  let discard = false;

  let headerText = "";
  switch (type) {
    case "head":
      headerText = i18n.t("AddHeader");
      break;
    case "cat":
      headerText = i18n.t("AddCategory");
      break;
    default:
      headerText = i18n.t("AddSubCategory");
      break;
  }

  if (type == "cat") {
    if (!isEmpty(data)) {
      if (data.title != title) {
        buttonDisable = false;
        discard = true;
      }
    } else {
      if (title != "") {
        buttonDisable = false;
        discard = true;
      }
    }
  } else {
    if (!isEmpty(data)) {
      if (data.title != title || data.image != img) {
        buttonDisable = false;
        discard = true;
      }
    } else {
      if (title != "" && pickedImage != null) buttonDisable = false;

      if (title != "" || pickedImage != null) discard = true;
    }
  }

  return (
    <Modal
      open={active}
      onClose={() => {
        if (discard) return confirmDiscard();
        else return onClose();
      }}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Slide direction="up" in={active} mountOnEnter unmountOnExit>
        <div className="bg-white w-full max-w-2xl  rounded-lg border-none outline-none shadow-lg flex flex-col">
          <div className="w-full h-12 p-3 border-b-2 border-gray-600 flex items-center justify-center rounded-t-lg">
            <p>{headerText}</p>
          </div>
          <div className="w-full h-full p-3 flex flex-col overflow-y-auto">
            {type != "cat" && (
              <div className="flex flex-col items-center justify-center">
                <img
                  src={img}
                  className={`${
                    type == "sub" ? "w-60" : "w-72"
                  } h-60 my-4 rounded-md border-2 border-primary-100`}
                />

                <CustomButton
                  onClick={onChooseImage}
                  style="primary"
                  size="medium"
                >
                  <p>{i18n.t("ChooseImage")}</p>
                </CustomButton>
              </div>
            )}

            {/* <TextAreaInput
              value={description}
              onTextChange={(text) => setDescription(text)}
              placeholder={i18n.t("Description")}
            /> */}
            <TextInput
              value={title}
              onTextChange={(text) => setTitle(text)}
              placeholder={i18n.t("Title")}
              size={"full"}
            />
          </div>

          <div className="p-3">
            <CustomButton
              disabled={buttonDisable}
              onClick={onSubmit}
              style="primary-light"
              size="full"
            >
              <p>{isEmpty(data) ? i18n.t("Save") : i18n.t("Edit")}</p>
            </CustomButton>
          </div>
        </div>
      </Slide>
    </Modal>
  );
}
