import Rating from "@mui/material/Rating";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import Avatar from "../../components/common/Avatar";
import CustomButton from "../../components/common/CustomButton";
import TextAreaInput from "../../components/common/TextAreaInput";
import i18n from "../../locale/I18";
import { updateTeacherRequest } from "../../redux/action/user.action";
import {
  selectorIsTeacherOrAdmin,
  selectorPublicData,
  selectorTeacher,
  selectorUser,
} from "../../redux/store/selectors";
import Colors from "../../utils/style/Colors";
import { IoIosAddCircleOutline } from "react-icons/io";
import SocialMediaModal from "../../components/home/SocialMediaModal";
import { setPublicDataRequest } from "../../redux/action/general.action";
import { isEmpty } from "lodash";
import { UploadFile } from "../../API/storage/Storage";

function AboutTab(props) {
  const {
    teacher,
    updateTeacher,
    isTeacherOrAdmin,
    publicData,
    user,
    setPublicData,
  } = props;
  const [teacherAbout, setTeacherAbout] = useState("");
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const history = useHistory();
  const [socialMediaModal, setSocialMediaModal] = useState({
    active: false,
    data: {},
  });
  const [socialMedia, setSocialMedia] = useState([]);

  useEffect(() => {
    if (publicData.socialMedia) setSocialMedia(publicData.socialMedia);
  }, [publicData]);

  const onEditSocialMedia = (item, index) => {
    console.log({ item, index });
    setSocialMediaModal({ active: true, data: { ...item, index } });
  };

  const onConfirmDeleteSocialMedia = (index) => {
    global.showConfirmAlert({
      title: i18n.t("Warning"),
      message: i18n.t("SureDeleteSocialMedia"),
      confirm: i18n.t("Delete"),
      onConfirm: () => deleteSocialMedia(index),
    });
  };

  const deleteSocialMedia = (index) => {
    const newSocialMedia = [...socialMedia];
    newSocialMedia.splice(index, 1);
    setPublicData({
      key: "socialMedia",
      value: newSocialMedia,
    });
    setSocialMedia(newSocialMedia);
  };

  const onSocialMediaMenu = (item, index) => {
    console.log({ index });
    const menuItems = [
      {
        title: i18n.t("Visit"),
        onClick: () => {
          onSocialMedia(item);
        },
      },
      {
        title: i18n.t("Edit"),
        onClick: () => {
          onEditSocialMedia(item, index);
        },
      },
      {
        title: i18n.t("Delete"),
        onClick: () => {
          onConfirmDeleteSocialMedia(index);
        },
      },
    ];
    global.showMenu({
      items: menuItems,
      menuTitle: `Do you need any action on this social media?`,
    });
  };

  const onAddSocialMedia = () => {
    setSocialMediaModal({ active: true, data: {} });
  };

  const onSocialMediaSubmit = async (data) => {
    let icon = data.icon;
    const { index, pickedFile, ...otherData } = data;
    console.log({ index, pickedFile, otherData });
    if (!isEmpty(data.pickedFile)) {
      const ref = `publicData/socialMedia/${data.title}`;
      const iconResult = await UploadFile(user?.accessToken, {
        file: data.pickedFile.file,
        ref,
        mime: data.pickedFile.mime,
      });
      if (!isEmpty(iconResult)) {
        icon = iconResult.downloadUrl;
      }
    }
    if (index >= 0) {
      const newSocialMedia = [...socialMedia];
      newSocialMedia[index] = { ...otherData, icon };
      console.log({ newSocialMedia });
      setPublicData({
        key: "socialMedia",
        value: newSocialMedia,
      });
      setSocialMedia(newSocialMedia);
    } else {
      const newSocialMedia = [...socialMedia, { ...otherData, icon }];
      setPublicData({
        key: "socialMedia",
        value: newSocialMedia,
      });
      setSocialMedia(newSocialMedia);
    }
  };

  useEffect(() => {
    setTeacherAbout(teacher?.about);
  }, [teacher]);

  const onUpdateTeacherAbout = async () => {
    setLoadingUpdate(true);
    await updateTeacher({ about: teacherAbout });
    setLoadingUpdate(false);
  };

  const onSendMessage = () => {
    history.push("/chat");
  };

  const onSocialMedia = (item) => {
    if (item.title == "email") {
      navigator.clipboard.writeText(item.link);
      global.showToast({
        duration: 2000,
        message: i18n.t("EmailAddressCopied"),
        variant: "success",
      });
    } else {
      window.open(item?.link, "_blank");
    }
  };

  const renderSocialMediaItems = (item, index) => {
    return (
      <img
        onClick={() => {
          if (isTeacherOrAdmin) {
            onSocialMediaMenu(item, index);
          } else {
            onSocialMedia(item);
          }
        }}
        src={item?.icon}
        className="mr-4 ml-4 w-10 h-10 cursor-pointer"
      />
    );
  };

  const disableButton =
    teacher.about == teacherAbout?.trim() || teacherAbout == "";

  console.log({ teacher });
  return (
    <div className="w-full px-4">
      <div className="w-full flex justify-between mb-8">
        <div className="flex items-center">
          <Avatar
            className="w-24 h-24 rounded-lg mr-3 shadow-sm"
            src={teacher?.photo}
          />

          <div>
            <p className="text-gray-400 font-bold text-xl font-sans">
              {i18n.t("Trainer")}
            </p>
            <p className="font-bold text-xl font-sans">{teacher?.fullName}</p>
          </div>
        </div>
        <div>
          <Rating
            name="read-only"
            value={teacher?.ratingAvg}
            readOnly
            style={{ color: Colors.ratingFill }}
          />
        </div>
      </div>
      <div className="w-full flex flex-col justify-between mb-8">
        {isTeacherOrAdmin ? (
          <div className="w-full flex flex-col items-center">
            <TextAreaInput
              value={teacherAbout}
              onTextChange={(text) => setTeacherAbout(text)}
            />
            <CustomButton
              disabled={disableButton}
              loading={loadingUpdate}
              size="medium"
              style="primary"
              onClick={onUpdateTeacherAbout}
            >
              <p>{i18n.t("Update")}</p>
            </CustomButton>
          </div>
        ) : (
          <p className="text-xl font-sans">{`${teacher?.about}`}</p>
        )}
      </div>
      {/* {!isTeacherOrAdmin && (
        <div className="w-full flex flex-col justify-between mb-8">
          <p className="font-bold text-xl font-sans">{i18n.t("HaveQuestion")}</p>
          <p className="text-lg font-sans">{i18n.t("HaveQuestionDesc")}</p>
          <CustomButton onClick={onSendMessage} size="small" style="primary">
            <p>{i18n.t("SendAMessage")}</p>
          </CustomButton>
        </div>
      )} */}
      <div className="w-full flex flex-col justify-between mb-8">
        <p className="font-bold text-xl font-sans">{i18n.t("SocialMedia")}</p>
        <p className="text-lg font-sans">{i18n.t("SocialMediaDesc")}</p>
        <div className="mt-4 flex items-center justify-center">
          {isTeacherOrAdmin && (
            <IoIosAddCircleOutline
              size="2.5rem"
              className="text-gray-400 mx-2 cursor-pointer"
              onClick={onAddSocialMedia}
            />
          )}
          {socialMedia?.map((sm, index) => renderSocialMediaItems(sm, index))}
        </div>
      </div>

      <SocialMediaModal
        active={socialMediaModal.active}
        data={socialMediaModal.data}
        onClose={() => {
          setSocialMediaModal({ active: false, data: {} });
        }}
        onSubmit={(data) => onSocialMediaSubmit(data)}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  teacher: selectorTeacher(state),
  isTeacherOrAdmin: selectorIsTeacherOrAdmin(state),
  publicData: selectorPublicData(state),
  user: selectorUser(state),
});

const mapDispatchToProps = {
  updateTeacher: updateTeacherRequest,
  setPublicData: setPublicDataRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutTab);
