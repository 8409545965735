import {GET_PAYMENT_DATA_RESPONSE} from '../constant/types';

const initialState = {
  paymentData: false,
};

export default (state = initialState, action = {}) => {
  const {payload, type} = action;

  switch (type) {
    case GET_PAYMENT_DATA_RESPONSE:
      return {
        ...state,
        paymentData: payload.params,
      };

    default:
      return state;
  }
};
