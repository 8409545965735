import {
  CONTENT_ASSEMBLER_KEY,
  GET_PUBLIC_DATA_REQUEST,
  GET_PUBLIC_DATA_RESPONSE,
  SET_PUBLIC_DATA_REQUEST,
} from '../constant/types';

export const setContentAssemblerKey = params => ({
  type: CONTENT_ASSEMBLER_KEY,
  payload: {params},
});

export const getPublicDataRequest = params => ({
  type: GET_PUBLIC_DATA_REQUEST,
  payload: {params},
});
export const getPublicDataResponse = params => ({
  type: GET_PUBLIC_DATA_RESPONSE,
  payload: {params},
});
export const setPublicDataRequest = params => ({
  type: SET_PUBLIC_DATA_REQUEST,
  payload: {params},
});
