import axios from "axios";
import { ServerAPI, VERSION } from "../../global/Config";
import { HandleError } from "../../utils/error/Error";

const URL = `${ServerAPI}/${VERSION}/category`;

export const GetAllCategories = async (token) => {
  const context = `Category @GetAllCategories`;
  try {
    const result = await axios.get(`${URL}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (error) {
    HandleError(context, error);
  }
};

export const CreateCategory = async (token, params) => {
  const context = `Category @CreateCategory`;
  try {
    const result = await axios.post(`${URL}`, params, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (error) {
    HandleError(context, error);
  }
};

export const UpdateCategory = async (token, id, params) => {
  const context = `Category @UpdateCategory`;
  try {
    const result = await axios.put(`${URL}/${id}`, params, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (error) {
    HandleError(context, error);
  }
};

export const DeleteCategory = async (token, id) => {
  const context = `Category @DeleteCategory`;
  try {
    const result = await axios.delete(`${URL}/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (error) {
    HandleError(context, error);
  }
};
