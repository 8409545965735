import React from "react";
import BaseView from "../../components/common/BaseView";

export default function HelpScreen() {
  return (
    <BaseView>
      <div className="w-full h-full">
        <p>help screen</p>
      </div>
    </BaseView>
  );
}
