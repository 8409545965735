import React from "react";
import PropTypes from "prop-types";

Spinner.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "white",
    "black",
    "gray",
    "primary",
    "primary-light",
    "primary-dark",
  ]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

export default function Spinner(props) {
  const { className, color = "gray", size = "medium" } = props;
  return (
    <div
      className={`${className} spnr-size-${size} spnr-color-${color} rounded-full ease-linear m-2 animate-spin`}
    />
  );
}
