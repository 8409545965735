import axios from "axios";
import { ServerAPI, VERSION } from "../../global/Config";
import { HandleError } from "../../utils/error/Error";

const URL = `${ServerAPI}/${VERSION}/favorite`;

export const GetMyFavorites = async (token) => {
  const context = `Favorite @GetMyFavorites`;
  try {
    const result = await axios.get(`${URL}/myfavorites`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (error) {
    HandleError(context, error);
  }
};

export const CreateFavorite = async (token, params) => {
  const context = `Favorite @CreateFavorite`;
  try {
    const result = await axios.post(`${URL}`, params, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (error) {
    HandleError(context, error);
  }
};

export const DeleteFavorite = async (token, id) => {
  const context = `Favorite @DeleteFavorite`;
  try {
    const result = await axios.delete(`${URL}/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (error) {
    HandleError(context, error);
  }
};
