import React from "react";
import PropTypes from "prop-types";

TextInput.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onTextChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium", "large", "full"]),
  type: PropTypes.oneOf(["email", "password", "text", "url", "file", "image"]),
  rightIcon: PropTypes.element,
  rightIconOnClick: PropTypes.func,
  className: PropTypes.string,
};

export default function TextInput(props) {
  const {
    onTextChange = () => {},
    disabled = false,
    value = "",
    size = "large",
    placeholder = "placeholder",
    type = "text",
    rightIcon = null,
    rightIconOnClick = () => {},
    className = "",
  } = props;
  return (
    <div
      className={`${className} inpt-${size} ${
        disabled ? "bg-gray-200" : "bg-gray-300 "
      } flex items-center rounded-md border-0 mb-2 mt-2`}
    >
      <input
        className={`w-full h-full outline-none text-black ${
          disabled ? "bg-gray-200" : "bg-gray-300 "
        }`}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onTextChange(e.target.value)}
        disabled={disabled}
        color="#f00"
      />
      {rightIcon && (
        <div
          onClick={rightIconOnClick}
          className="flex items-center justify-center cursor-pointer mr-1 ml-1"
        >
          {rightIcon}
        </div>
      )}
    </div>
  );
}
