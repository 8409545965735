import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Slide } from "@mui/material";
import i18n from "../../locale/I18";
import TextInput from "../common/TextInput";
import CustomButton from "../common/CustomButton";

ForgotPasswordModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default function ForgotPasswordModal(props) {
  const [email, setEmail] = useState("");
  const { active, onClose, onSubmit } = props;

  const disableButton = email == "";
  return (
    <Modal
      open={active}
      onClose={onClose}
      style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Slide direction="up" in={active} mountOnEnter unmountOnExit>
        <Box className="w-4/5 max-w-2xl h-3/6 bg-white border-none outline-none rounded-lg p-4 flex flex-col justify-around">
          <div className="w-full ">
            <p className="text-black text-2xl">{i18n.t("ForgotPasswordTitle")}</p>
          </div>
          <div className="w-full">
            <p className="text-left">{i18n.t("EnterYourEmail")}</p>
            <TextInput
              type={"email"}
              value={email}
              placeholder={i18n.t("Email")}
              size={"full"}
              onTextChange={(text) => {
                setEmail(text);
              }}
            />
          </div>
          <div className="w-full">
            <CustomButton
              disabled={disableButton}
              onClick={() => {
                onSubmit(email);
              }}
              size="full"
              style="primary-light"
            >
              <p>{i18n.t("Submit")}</p>
            </CustomButton>
          </div>
        </Box>
      </Slide>
    </Modal>
  );
}
