import { call, put, select } from "redux-saga/effects";
import { RenewAccess } from "../../../API/auth/Auth";
import { setIsTeacherOrAdmin } from "../../action/user.action";
import { selectorPaymentData, selectorUser } from "../../store/selectors";
import { getCategories } from "./category";
import { getComplete } from "./complete";
import { getFavorites } from "./favorite";
import { getPublicData } from "./general";
import { getTeacher } from "./user";

export function* initialCall() {
  // yield call(DropDownloadTable);
  yield call(getPublicData);
  // yield call(SelectQueryData);
  // yield call(setFCM);
}

export function* initialCallLoggedIn() {
  const user = yield select(selectorUser);
  const paymentData = yield select(selectorPaymentData);
  yield call(RenewAccess, user?.userData?.id);
  yield put(
    setIsTeacherOrAdmin(
      user?.userData?.role === "teacher" || user?.userData?.role === "admin"
    )
  );
  yield call(getTeacher);
  yield call(getCategories);
  yield call(getComplete);
  yield call(getFavorites);
  // if (!paymentData) {
  //   navigate('SubscriptionsScreen', {force: true});
  // }
}
