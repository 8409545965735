import React from "react";
import PropTypes from "prop-types";
import Spinner from "./Spinner";

CustomButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.oneOf([
    "transparent",
    "white",
    "black",
    "primary",
    "primary-light",
    "primary-dark",
  ]),
  size: PropTypes.oneOf(["small", "medium", "large", "full"]),
  className: PropTypes.string,
  loading: PropTypes.bool,
};

function CustomButton(props) {
  const {
    onClick = () => {},
    disabled = false,
    loading = false,
    children,
    style = "primary",
    size = "large",
    className = "",
  } = props;
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${className} shadow-md flex justify-around items-center mt-2 mb-2  ${
        disabled
          ? `btn-${size} 
                   bg-gray-300 text-gray-400 cursor-default`
          : `btn-${style} btn-${size} transform duration-200 ease-in-out active:scale-95`
      }`}
    >
      {loading ? <Spinner /> : children}
    </button>
  );
}

export default CustomButton;
