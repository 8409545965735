import React, { useEffect, useRef, useState } from "react";
import { IoIosCloseCircleOutline, IoMdArrowRoundBack } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { BiSend } from "react-icons/bi";
import Avatar from "./Avatar";
import { isEmpty } from "lodash";
import i18n from "../../locale/I18";
import moment from "moment";
import {
  CreateChatRoom,
  CreateMessage,
  GetMyChatsRooms,
  GetRoomMessages,
} from "../../API/chat/Chat";
import io from "socket.io-client";
import { ServerAPI } from "../../global/Config";

const socket = io(ServerAPI, {
  reconnection: false,
});

export default function ChatComponent(props) {
  const { teacher, onClose, onBack, user, student = {}, room = null } = props;
  const messagesCtrRef = useRef(null);

  const [text, setText] = useState("");
  const [messages, setMessages] = useState([]);
  let receiver = {
    id: !isEmpty(student) ? student.id : teacher.id,
    fcm: !isEmpty(student) ? student.fcm : teacher.fcm,
    photo: !isEmpty(student) ? student.photo : teacher.photo,
    fullName: !isEmpty(student) ? student.fullName : teacher.fullName,
  };
  const [roomId, setRoomId] = useState(room);
  console.log({ receiver, roomId });

  const getRoom = async () => {
    const result = await GetMyChatsRooms(user?.accessToken);
    if (!isEmpty(result)) {
      setRoomId(result[0]?.id);
    }
  };

  const getMessages = async () => {
    const response = await GetRoomMessages(user?.accessToken, roomId);
    if (!isEmpty(response)) {
      response.reverse();
      setMessages(response);
    }
  };

  useEffect(() => {
    if (roomId != null) {
      getMessages();
    }
    if (roomId != null && socket) {
      join();
    }
  }, [roomId]);

  useEffect(() => {
    if (room == null) {
      getRoom();
    }
    connect();
    if (!isEmpty(student)) {
      receiver = student;
    }

    return () => {
      disconnect();
    };
  }, []);

  useEffect(() => {
    socket.on("message", messageListener);
    scrollDown();
  }, [messages]);

  const messageListener = (message) => {
    if (message?.user?.userId != user?.userData?.id) {
      setMessages([...messages, message]);
    }
  };

  const connect = () => {
    socket.connect();
  };

  const disconnect = () => {
    socket.disconnect();
    socket.off();
  };
  console.log({ messages });
  const join = () => {
    socket.emit("join", { userId: user?.userData?.id, room: roomId }, () => {
      console.log("connected to the room " + roomId);
    });
  };

  const onSend = async () => {
    let currentRoom = roomId;
    if (currentRoom == null) {
      const result = await CreateChatRoom(user?.accessToken, {
        teacherId: teacher.id,
      });
      setRoomId(result.id);
      currentRoom = result.id;
    }

    const messageObj = {
      data: text,
      chatRoomId: currentRoom,
      receiverId: receiver.id,
      type: "text",
    };
    setText("");

    const messageResult = await CreateMessage(user?.accessToken, messageObj);

    socket.emit("sendMessage", messageResult, () => {
      console.log("message sent successfully!");
    });

    socket.emit(
      "updateChatThread",
      {
        room: currentRoom,
      },
      () => {
        console.log("chat thread updated successfully!");
      }
    );

    setMessages([...messages, messageResult]);
    scrollDown();
  };

  const scrollDown = () => {
    messagesCtrRef.current.scrollTop = messagesCtrRef.current.scrollHeight;
  };

  const renderMessages = () => {
    return messages.map((item, index) => {
      const myMessage = item.receiverId != user.userData.id;
      let prevMessage = null;
      if (index > 0) prevMessage = messages[index - 1];

      const messageDif = moment(item?.createdAt).diff(prevMessage?.createdAt);
      const shouldRenderDate =
        Math.abs(messageDif / 1000) > 24 * 60 * 60 ? true : false;

      let nextMine = false;
      if (index + 1 != messages.length)
        nextMine = messages[index + 1].receiverId != user.userData.id;

      return (
        <>
          {shouldRenderDate && (
            <div className="flex self-center bg-gray-100 rounded-full my-2 p-2 ">
              <p className="text-xs font-medium text-gray-500">
                {moment(item.createdAt).format("DD MMMM YYYY")}
              </p>
            </div>
          )}
          <div
            className={` p-2 rounded-md  flex flex-col max-w-64 ${
              myMessage
                ? "rounded-tr-none bg-blue-100 self-end"
                : "rounded-tl-none bg-gray-200"
            }
          ${nextMine ? "mb-1" : "mb-2"}
          `}
          >
            <p>{item.data}</p>
            <p className="text-xs font-medium text-gray-400">
              {moment(item.createdAt).format("hh:mm A")}
            </p>
          </div>
        </>
      );
    });
  };

  let buttonDisable = true;
  if (text.trim() != "") buttonDisable = false;

  return (
    <div className="flex flex-col h-full w-full">
      <div className="flex items-center justify-between p-3 bg-primary-100 rounded-t-lg">
        <div className="flex items-center">
          {onBack && (
            <IoMdArrowRoundBack
              size="1.5rem"
              onClick={onBack}
              className="cursor-pointer text-gray-500 mr-4"
            />
          )}
          <Avatar
            src={!isEmpty(receiver) ? receiver?.photo : teacher?.photo}
            size="3rem"
            rounded
          />
          <p className="ml-2 ">
            {!isEmpty(receiver) ? receiver?.fullName : teacher?.fullName}
          </p>
        </div>
        <MdClose
          size="1.5rem"
          onClick={onClose}
          className="cursor-pointer text-gray-500"
        />
      </div>
      <div className="flex h-full flex-col box-border relative">
        <div
          className="flex flex-col p-4 overflow-y-auto max-h-full box-border absolute  top-0 bottom-16 left-0 right-0"
          ref={messagesCtrRef}
        >
          {isEmpty(messages) ? (
            <div className="bg-gray-200 p-2 rounded-md rounded-tl-none flex flex-col max-w-64">
              <p>{i18n.t("HaveQuestionDesc")}</p>
              <p className="text-sm font-medium text-gray-400">
                {moment().format("hh:mm A")}
              </p>
            </div>
          ) : (
            renderMessages()
          )}
        </div>
        <div className="flex items-center justify-between w-full px-4 py-3 absolute bottom-0 ">
          <input
            className="h-full bg-gray-100 w-full mr-4 rounded-lg outline-none border-gray-200 border-2 py-1 px-2"
            onChange={(e) => {
              setText(e.target.value);
            }}
            value={text}
          />
          <BiSend
            size="1.5rem"
            onClick={!buttonDisable && onSend}
            className={`${
              buttonDisable
                ? "bg-gray-400"
                : "bg-green-500 hover:bg-green-600 cursor-pointer"
            }   text-white w-8 h-8 rounded-full p-2`}
          />
        </div>
      </div>
    </div>
  );
}
