import {GET_FAVORITES_RESPONSE} from '../constant/types';

const initialState = {
  favorites: [],
};

export default (state = initialState, action = {}) => {
  const {payload, type} = action;
  switch (type) {
    // Manage setIsLoadingRequest action to change loading status into store
    case GET_FAVORITES_RESPONSE:
      return {
        ...state,
        favorites: [...payload.params],
      };

    default:
      return state;
  }
};
