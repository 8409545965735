import { Modal, Slide } from "@mui/material";
import React, { useState } from "react";
import i18n from "../../locale/I18";

export default function ConfirmAlert() {
  const initialState = {
    title: i18n.t("Warning"),
    message: "Message",
    cancelClassName: "",
    confirmText: "Ok",
    confirmClassName: "",
    singleButton: false,
    force: false,
    onConfirm: () => {},
  };
  const [state, setState] = useState(initialState);
  const [active, setActive] = useState(false);

  //

  const setInitialValues = () => {
    setTimeout(() => {
      setState(initialState);
    }, 500);
  };

  const OnClose = () => {
    if (!state.force) {
      setActive(false);
      setInitialValues();
    }
  };

  const OnCancel = () => {
    setActive(false);
    setInitialValues();
  };

  const OnConfirm = () => {
    state.onConfirm();
  };

  const showConfirmAlert = (
    params = {
      title: i18n.t("Warning"),
      message: "",
      confirmText: i18n.t("Ok"),
      confirmClassName: "",
      cancelClassName: "",
      singleButton: false,
      onConfirm: () => {},
    }
  ) => {
    setState({
      ...initialState,
      ...params,
      onConfirm: () => {
        params.onConfirm();
        OnCancel();
      },
    });
    setActive(true);
  };

  global.showConfirmAlert = showConfirmAlert;

  return (
    <Modal
      open={active}
      onClose={OnClose}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Slide direction="up" in={active} mountOnEnter unmountOnExit>
        <div className="bg-white rounded-lg p-4 outline-none">
          <div className="mb-3">
            <p className="text-xl text-black">{state.title}</p>
          </div>
          <div className="mb-3">
            <p className="text-lg text-black">{state.message}</p>
          </div>
          <div className="w-full flex items-center justify-center">
            {state.singleButton ? (
              <div
                onClick={() => OnConfirm()}
                className="px-3 py-2 rounded-lg bg-primary-200 flex items-center justify-center text-white cursor-pointer"
              >
                <p>{i18n.t("Ok")}</p>
              </div>
            ) : (
              <div className="w-full flex items-center justify-center">
                <div
                  onClick={() => OnCancel()}
                  className={`${state.cancelClassName} bg-red-400 px-3 py-2 rounded-lg flex items-center justify-center text-white cursor-pointer mx-2`}
                >
                  {i18n.t("Cancel")}
                </div>
                <div
                  onClick={() => OnConfirm()}
                  className={`${state.confirmClassName} bg-green-400 px-3 py-2 rounded-lg flex items-center justify-center text-white cursor-pointer mx-2`}
                >
                  {state.confirmText}
                </div>
              </div>
            )}
          </div>
        </div>
      </Slide>
    </Modal>
  );
}
