import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Modal, Slide, Tab } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import i18n from "../../locale/I18";
import { selectorUser } from "../../redux/store/selectors";
import Colors from "../../utils/style/Colors";
import PasswordTab from "./PasswordTab";
import PersonalInfoTab from "./PersonalInfoTab";

function EditProfileModal(props) {
  const { user, active, onClose } = props;
  const [tabValue, setTabValue] = useState("personalInfo");

  const history = useHistory();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    // history.push(`/#${newValue}`);
  };

  return (
    <Modal
      open={active}
      onClose={onClose}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        outline: "none",
        border: "none",
      }}
    >
      <Slide direction="up" in={active} mountOnEnter unmountOnExit>
        <div className="w-11/12 max-w-md h-3/4 bg-white rounded-lg p-4">
          <TabContext value={tabValue}>
            <TabList
              className="w-full"
              centered
              onChange={handleChange}
              aria-label="wrapped label tabs example"
              TabIndicatorProps={{
                style: {
                  backgroundColor: Colors.primary,
                  color: Colors.primary,
                },
              }}
              allowScrollButtonsMobile
            >
              <Tab
                className="font-sans font-bold capitalize text-lg"
                style={{
                  color: tabValue == "personalInfo" ? Colors.primary : "black",
                }}
                value="personalInfo"
                label={i18n.t("PersonalInfo")}
              />
              <Tab
                className="font-sans font-bold capitalize text-lg"
                style={{
                  color: tabValue == "password" ? Colors.primary : "black",
                }}
                value="password"
                label={i18n.t("Password")}
              />
            </TabList>
            <TabPanel className="w-full" value="personalInfo">
              <PersonalInfoTab onClose={onClose} />
            </TabPanel>
            <TabPanel className="w-full" value="password">
              <PasswordTab onClose={onClose} />
            </TabPanel>
          </TabContext>
        </div>
      </Slide>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  user: selectorUser(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileModal);
