import { get, isEmpty } from "lodash";
import { call, put, select } from "redux-saga/effects";
import {
  CreateFavorite,
  DeleteFavorite,
  GetMyFavorites,
} from "../../../API/favorite/Favorite";

import { getFavoritesResponse } from "../../action/favorite.action";
import { selectorFavorites, selectorUser } from "../../store/selectors";

export function* getFavorites() {
  const user = yield select(selectorUser);
  const favorites = yield call(GetMyFavorites, user?.accessToken);
  if (!isEmpty(favorites)) yield put(getFavoritesResponse(favorites));
}

export function* createFavorite(params) {
  params = get(params, "payload.params");
  const user = yield select(selectorUser);
  const favorites = yield select(selectorFavorites);
  const result = yield call(CreateFavorite, user?.accessToken, params);
  if (!isEmpty(result)) {
    favorites.unshift(result);
    yield put(getFavoritesResponse(favorites));
  }
}

export function* deleteFavorite(params) {
  params = get(params, "payload.params");
  const user = yield select(selectorUser);
  const favorites = yield select(selectorFavorites);
  const result = yield call(DeleteFavorite, user?.accessToken, params.id);
  if (!isEmpty(result)) {
    const newFavorites = favorites.filter((e) => e.id != params.id);
    yield put(getFavoritesResponse(newFavorites));
  }
}
