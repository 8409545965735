import React, { useEffect, useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { ForgotPassword, Signin } from "../../API/auth/Auth";
import CustomButton from "../../components/common/CustomButton";
import TextInput from "../../components/common/TextInput";
import ForgotPasswordModal from "../../components/landing/ForgotPasswordModal";
import i18n from "../../locale/I18";
import { initialCallRequest } from "../../redux/action/initial.action";
import { selectorPublicData } from "../../redux/store/selectors";

function SignInScreen(props) {
  const { publicData, initialCall } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showForgotPassModal, setShowForgotPassModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    initialCall();
  }, []);

  console.log({ publicData });

  const onSignIn = async () => {
    setLoading(true);
    await Signin({ email: email.trim().toLowerCase(), password });
    setLoading(false);
    history.push("/");
  };

  const onForgotPassword = () => {
    setShowForgotPassModal(true);
  };

  const onSubmitForgotPassword = async (email) => {
    const response = await ForgotPassword(email);
    if (response === "done")
      global.showConfirmAlert({
        title: i18n.t("Warning"),
        message: i18n.t("ChangePasswordEmailSent"),
        confirmText: i18n.t("Ok"),
        onConfirm: () => {
          setShowForgotPassModal(false);
        },
        singleButton: true,
      });
  };

  return (
    <div className="primary-light h-full flex justify-center items-center">
      <div className="rounded-lg relative bg-white shadow-lg flex h-5/6 w-10/12">
        <div className="h-full w-full md:w-4/12 bg-black rounded-lg md:rounded-r-none relative">
          <video
            className="h-full w-full rounded-lg md:rounded-r-none"
            autoPlay
            loop
            muted
          >
            <source src={publicData?.videoBackground} />
          </video>
          <div className="bg-black w-full h-full absolute top-0 right-0 opacity-50 md:hidden rounded-lg" />
        </div>
        <div className="h-full absolute top-0 z-20 right-0 w-full self-center p-4 flex flex-col items-center text-white md:w-8/12 md:text-black">
          <div className="flex items-center justify-center h-1/3">
            <p className="text-3xl font-logo-light">{i18n.t("AppName")}</p>
          </div>
          <div className="w-full max-w-xl flex flex-col items-center  h-2/3">
            <TextInput
              placeholder={i18n.t("Email")}
              type={"email"}
              size={"full"}
              value={email}
              onTextChange={(text) => {
                setEmail(text);
              }}
            />
            <TextInput
              placeholder={i18n.t("Password")}
              type={showPassword ? "text" : "password"}
              size={"full"}
              rightIcon={
                showPassword ? (
                  <FiEye className="text-black" />
                ) : (
                  <FiEyeOff className="text-black" />
                )
              }
              rightIconOnClick={() => {
                setShowPassword(!showPassword);
              }}
              value={password}
              onTextChange={(text) => {
                setPassword(text);
              }}
            />
            <p
              onClick={onForgotPassword}
              className="mt-1 text-right text-white md:text-gray-500 cursor-pointer underline flex self-end"
            >
              {i18n.t("ForgotPassword")}
            </p>

            <CustomButton
              loading={loading}
              onClick={onSignIn}
              className="mb-4 mt-8"
              size="full"
              style="primary-light"
            >
              <p>{i18n.t("SignIn")}</p>
            </CustomButton>
          </div>
          {/* <div className="w-full max-w-xl mt-10 h-full flex flex-col items-center">
            <div className="flex w-full justify-between items-center">
              <div className="w-full bg-gray-200 h-px" />
              <p className="text-white md:text-gray-500 w-full text-center mr-3 ml-3">
                {i18n.t("OrConnectWith")}
              </p>
              <div className="w-full bg-gray-200 h-px" />
            </div>
            <RoundButton
              onClick={onGoogleSignIn}
              className="mt-auto mb-auto"
              size="medium"
              style="white"
            >
              <FcGoogle className="w-full h-full" />
            </RoundButton>
            <div onClick={onSignUp} className="w-full flex justify-center">
              <p className="text-white md:text-gray-500 cursor-pointer underline">
                {i18n.t("DontHaveAccount")}
              </p>
              <p className=" text-primary-light md:text-primary cursor-pointer underline">
                {i18n.t("SignUp")}
              </p>
            </div>
          </div> */}
        </div>
      </div>
      <ForgotPasswordModal
        active={showForgotPassModal}
        onClose={() => {
          setShowForgotPassModal(false);
        }}
        onSubmit={(email) => {
          onSubmitForgotPassword(email);
        }}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  publicData: selectorPublicData(state),
});

const mapDispatchToProps = {
  initialCall: initialCallRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInScreen);
