import React, { useState } from "react";
import { useHistory } from "react-router";

const AuthContext = React.createContext();

function AuthProvider(props) {
  const { user } = props;
  const [isAuth, setIsAuth] = useState(false);
  const history = useHistory();

  global.signOut = () => {
    setIsAuth(false);
    history.replace("/");
  };
  global.signIn = async () => {
    setIsAuth(true);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuth,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer, AuthContext };
