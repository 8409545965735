import {
  DELETE_CATEGORY_REQUEST,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_RESPONSE,
  SET_CATEGORY_REQUEST,
} from '../constant/types';

export const getCategoriesRequest = params => ({
  type: GET_CATEGORIES_REQUEST,
  payload: {params},
});
export const getCategoriesResponse = params => ({
  type: GET_CATEGORIES_RESPONSE,
  payload: {params},
});

export const setCategoryRequest = params => ({
  type: SET_CATEGORY_REQUEST,
  payload: {params},
});

export const deleteCategoryRequest = params => ({
  type: DELETE_CATEGORY_REQUEST,
  payload: {params},
});
