export function ValidateForm(params) {
  //check all fields has value
  let validate = true;
  Object.keys(params).some((param) => {
    if (params[param] === "") {
      validate = false;
      global.showToast({
        duration: 5000,
        message: "EmptyField",
        variant: "error",
      });
    } else {
      if (
        param === "email" &&
        !params[param].includes("@") &&
        !params[param].includes(".")
      ) {
        validate = false;
        global.showToast({
          duration: 5000,
          message: "BadEmail",
          variant: "error",
        });
      }
      if (param === "password" && params[param].length < 8) {
        validate = false;
        global.showToast({
          duration: 5000,
          message: "ShortPassword",
          variant: "error",
        });
      }
    }
  });
  return validate;
}

export const UnFreezObject = (object) => {
  let result = {};
  Object.keys(object).map((key) => {
    console.log({ key, value: object[key] });
    result[key] = object[key];
    if (object[key] instanceof Array) {
      result[key] = UnFreezArray(object[key]);
    }
  });
  return result;
};

export const UnFreezArray = (array) => {
  let result = [];
  array.map((item) => {
    if (item instanceof Object) {
      item = UnFreezObject(item);
    }
    result.push(item);
  });
  return result;
};

export const IDGenerator = () => {
  const list = "abcdefghijklmnopqrstuwxyz123456789ABCDEFGHIJKLMNPQRSTUVWXYZ";
  var res = "";
  for (var i = 0; i < 20; i++) {
    var rnd = Math.floor(Math.random() * list.length);
    res = res + list.charAt(rnd);
  }
  return res;
};

export const CodeGenerator = (n) => {
  const list = "0123456789";
  var res = "";
  for (var i = 0; i < n; i++) {
    var rnd = Math.floor(Math.random() * list.length);
    res = res + list.charAt(rnd);
  }
  return res;
};
