//initial calls
export const INITIAL_CALLS_REQUEST = "INITIAL_CALLS_REQUEST";
export const INITIAL_CALLS_LOGGED_IN_REQUEST =
  "INITIAL_CALLS_LOGGED_IN_REQUEST";
export const INITIAL_CALLS_RESPONSE = "INITIAL_CALLS_RESPONSE";

//Current User
export const CURRENT_USER = "CURRENT_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

//Category
export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_RESPONSE = "GET_CATEGORIES_RESPONSE";
export const SET_CATEGORY_REQUEST = "SET_CATEGORY_REQUEST";
export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";

//Content
export const CREATE_CONTENT_REQUEST = "CREATE_CONTENT_REQUEST";
export const UPDATE_CONTENT_REQUEST = "UPDATE_CONTENT_REQUEST";
export const DELETE_CONTENT_REQUEST = "DELETE_CONTENT_REQUEST";

//general
export const CONTENT_ASSEMBLER_KEY = "CONTENT_ASSEMBLER_KEY_REQUEST";
export const GET_PUBLIC_DATA_REQUEST = "GET_PUBLIC_DATA_REQUEST";
export const GET_PUBLIC_DATA_RESPONSE = "GET_PUBLIC_DATA_RESPONSE";
export const SET_PUBLIC_DATA_REQUEST = "SET_PUBLIC_DATA_REQUEST";

//user and teacher
export const GET_TEACHER_DATA_REQUEST = "GET_TEACHER_DATA_REQUEST";
export const GET_TEACHER_DATA_RESPONSE = "GET_TEACHER_DATA_RESPONSE";
export const GET_USER_DATA_REQUEST = "GET_USER_DATA_REQUEST";
export const GET_USER_DATA_RESPONSE = "GET_USER_DATA_RESPONSE";
export const SET_USER_DATA_REQUEST = "SET_USER_DATA_REQUEST";
export const REMOVE_USER_DATA_REQUEST = "REMOVE_USER_DATA_REQUEST";
export const UPDATE_TEACHER_REQUEST = "UPDATE_TEACHER_REQUEST";
export const SET_IS_TEACHER_OR_ADMIN = "SET_IS_TEACHER_OR_ADMIN";

//Classes
export const GET_CLASSES_CONTENT_REQUEST = "GET_CLASSES_CONTENT_REQUEST";
export const GET_CLASSES_CONTENT_RESPONSE = "GET_CLASSES_CONTENT_RESPONSE";
export const SET_CLASSES_CATEGORY_REQUEST = "SET_CLASSES_CATEGORY_REQUEST";
export const SET_CLASSES_SUB_CATEGORY_REQUEST =
  "SET_CLASSES_SUB_CATEGORY_REQUEST";
export const DELETE_CLASSES_DATA_REQUEST = "DELETE_CLASSES_DATA_REQUEST";
export const SET_CLASSES_CONTENT_REQUEST = "SET_CLASSES_CONTENT_REQUEST";
export const DELETE_CLASSES_CONTENT_REQUEST = "DELETE_CLASSES_CONTENT_REQUEST";

//WorkShops
export const GET_WORKSHOPS_CONTENT_REQUEST = "GET_WORKSHOPS_CONTENT_REQUEST";
export const GET_WORKSHOPS_CONTENT_RESPONSE = "GET_WORKSHOPS_CONTENT_RESPONSE";
export const SET_WORKSHOPS_CATEGORY_REQUEST = "SET_WORKSHOPS_CATEGORY_REQUEST";
export const SET_WORKSHOPS_SUB_CATEGORY_REQUEST =
  "SET_WORKSHOPS_SUB_CATEGORY_REQUEST";
export const DELETE_WORKSHOPS_DATA_REQUEST = "DELETE_WORKSHOPS_DATA_REQUEST";
export const SET_WORKSHOPS_CONTENT_REQUEST = "SET_WORKSHOPS_CONTENT_REQUEST";
export const DELETE_WORKSHOPS_CONTENT_REQUEST =
  "DELETE_WORKSHOPS_CONTENT_REQUEST";

//Blog
export const GET_BLOG_CONTENT_REQUEST = "GET_BLOG_CONTENT_REQUEST";
export const GET_BLOG_CONTENT_RESPONSE = "GET_BLOG_CONTENT_RESPONSE";
export const SET_BLOG_CATEGORY_REQUEST = "SET_BLOG_CATEGORY_REQUEST";
export const SET_BLOG_SUB_CATEGORY_REQUEST = "SET_BLOG_SUB_CATEGORY_REQUEST";
export const DELETE_BLOG_DATA_REQUEST = "DELETE_BLOG_DATA_REQUEST";
export const SET_BLOG_CONTENT_REQUEST = "SET_BLOG_CONTENT_REQUEST";
export const DELETE_BLOG_CONTENT_REQUEST = "DELETE_BLOG_CONTENT_REQUEST";

//Favorite
export const GET_FAVORITES_REQUEST = "GET_FAVORITES_REQUEST";
export const GET_FAVORITES_RESPONSE = "GET_FAVORITES_RESPONSE";
export const CREATE_FAVORITE_REQUEST = "CREATE_FAVORITE_REQUEST";
export const DELETE_FAVORITE_REQUEST = "DELETE_FAVORITE_REQUEST";

//Complete
export const GET_COMPLETES_REQUEST = "GET_COMPLETES_REQUEST";
export const GET_COMPLETES_RESPONSE = "GET_COMPLETES_RESPONSE";
export const SET_COMPLETE_REQUEST = "SET_COMPLETE_REQUEST";

//Chat
export const GET_CHAT_MESSAGES_REQUEST = "GET_CHAT_MESSAGES_REQUEST";
export const GET_CHAT_MESSAGES_RESPONSE = "GET_CHAT_MESSAGES_RESPONSE";
export const GET_CHAT_THREADS_REQUEST = "GET_CHAT_THREADS_REQUEST";
export const GET_CHAT_THREADS_RESPONSE = "GET_CHAT_THREADS_RESPONSE";
export const SET_MESSAGE_REQUEST = "SET_MESSAGE_REQUEST";

//payment
export const GET_PAYMENT_DATA_REQUEST = "GET_PAYMENT_DATA_REQUEST";
export const GET_PAYMENT_DATA_RESPONSE = "GET_PAYMENT_DATA_RESPONSE";
