import axios from "axios";
import { ServerAPI, VERSION } from "../../global/Config";
import { HandleError } from "../../utils/error/Error";

const URL = `${ServerAPI}/${VERSION}/complete`;

export const GetMyCompletes = async (token) => {
  const context = `Complete @GetMyCompletes`;
  try {
    const result = await axios.get(`${URL}/mycompletes`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (error) {
    HandleError(context, error);
  }
};

export const CreateComplete = async (token, params) => {
  const context = `Complete @CreateComplete`;
  try {
    const result = await axios.post(`${URL}`, params, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (error) {
    HandleError(context, error);
  }
};

export const DeleteComplete = async (token, id) => {
  const context = `Complete @DeleteComplete`;
  try {
    const result = await axios.put(`${URL}/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (error) {
    HandleError(context, error);
  }
};
