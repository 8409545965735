import { get, isEmpty } from "lodash";
import moment from "moment";
import { call, put, select } from "redux-saga/effects";
import {
  CreateContent,
  DeleteContent,
  UpdateContent,
} from "../../../API/content/Content";
import { DeleteFile, UploadFile } from "../../../API/storage/Storage";
import { ServerAPI } from "../../../global/Config";
import { getCategoriesResponse } from "../../action/category.action";
import { setContentAssemblerKey } from "../../action/general.action";
import {
  selectorCategories,
  selectorContentAssemblerKey,
  selectorUser,
} from "../../store/selectors";

export function* createContent(params) {
  params = get(params, "payload.params");
  const user = yield select(selectorUser);
  const categories = yield select(selectorCategories);
  const contentAssemblerKey = yield select(selectorContentAssemblerKey);

  console.log({ params });

  global.showToast({
    duration: 3000,
    message: "StartUploading",
    variant: "info",
  });

  let ref = `category/${params.categoryId}/${moment().unix()}`,
    result = null,
    fileResult = null,
    index = null,
    head = [...categories.head],
    cat = [...categories.cat],
    sub = [...categories.sub];
  const { file, ...otherParams } = params;
  switch (params.type) {
    case "text":
      //text content
      result = yield call(CreateContent, user?.accessToken, params);
      break;
    case "image":
      //image content
      fileResult = yield call(UploadFile, user?.accessToken, {
        file: file.file,
        ref,
        mime: file.mime,
      });
      otherParams.data = fileResult.downloadUrl;
      result = yield call(CreateContent, user?.accessToken, otherParams);
      // if (!isEmpty(result)) {
      //   const index = categories?.sub?.findIndex(
      //     e => e.id == params.categoryId,
      //   );
      //   categories?.sub[index]?.contents?.push(result);
      // }
      break;
    case "video":
      //video content
      fileResult = yield call(UploadFile, user?.accessToken, {
        file: file.file,
        ref,
        mime: file.mime,
      });
      otherParams.data = fileResult.downloadUrl;
      result = yield call(CreateContent, user?.accessToken, otherParams);
      // if (!isEmpty(result)) {
      //   const index = categories?.sub?.findIndex(
      //     e => e.id == params.categoryId,
      //   );
      //   categories?.sub[index]?.contents?.push(result);
      // }
      break;
    case "audio":
      //audio content
      fileResult = yield call(UploadFile, user?.accessToken, {
        file: file.file,
        ref,
        mime: file.mime,
      });
      otherParams.data = fileResult.downloadUrl;
      result = yield call(CreateContent, user?.accessToken, otherParams);
      // if (!isEmpty(result)) {
      //   const index = categories?.sub?.findIndex(
      //     e => e.id == params.categoryId,
      //   );
      //   categories?.sub[index]?.contents?.push(result);
      // }
      break;

    default:
      break;
  }

  if (!isEmpty(result)) {
    index = sub?.findIndex((e) => e.id == params.categoryId);
    sub[index]?.contents?.push(result);
  }

  yield put(getCategoriesResponse({ head, cat, sub }));
  yield put(setContentAssemblerKey(contentAssemblerKey + 1));

  global.showToast({
    duration: 3000,
    message: "UploadDone",
    variant: "success",
  });
}

export function* updateContent(params) {
  params = get(params, "payload.params");
  const user = yield select(selectorUser);
  const categories = yield select(selectorCategories);
  const contentAssemblerKey = yield select(selectorContentAssemblerKey);
  const { file, index, categoryId, ...otherParams } = params;

  console.log({ params });

  let ref = `${params.data.split(ServerAPI).pop()}`,
    result = null,
    fileResult = null;
  switch (params.type) {
    case "text":
      //text content
      result = yield call(
        UpdateContent,
        user?.accessToken,
        otherParams.id,
        otherParams
      );
      break;
    case "image":
      //image content
      if (!isEmpty(file)) {
        global.showToast({
          duration: 3000,
          message: "StartUploading",
          variant: "info",
        });
        fileResult = yield call(UploadFile, user?.accessToken, {
          file: file.file,
          ref,
          mime: file.mime,
        });
        otherParams.data = fileResult.downloadUrl;
      }
      result = yield call(
        UpdateContent,
        user?.accessToken,
        otherParams.id,
        otherParams
      );

      break;
    case "video":
      //video content
      if (!isEmpty(file)) {
        global.showToast({
          duration: 3000,
          message: "StartUploading",
          variant: "info",
        });
        fileResult = yield call(UploadFile, user?.accessToken, {
          file: file.file,
          ref,
          mime: file.mime,
        });
        otherParams.data = fileResult.downloadUrl;
      }

      break;
    case "audio":
      //audio content
      if (!isEmpty(file)) {
        global.showToast({
          duration: 3000,
          message: "StartUploading",
          variant: "info",
        });
        fileResult = yield call(UploadFile, user?.accessToken, {
          file: file.file,
          ref,
          mime: file.mime,
        });
        otherParams.data = fileResult.downloadUrl;
      }
      break;

    default:
      break;
  }

  result = yield call(
    UpdateContent,
    user?.accessToken,
    otherParams.id,
    otherParams
  );
  if (result == "done") {
    const subIndex = categories?.sub?.findIndex(
      (e) => e.id == params.categoryId
    );
    categories.sub[subIndex].contents[index] = otherParams;
  }

  yield put(getCategoriesResponse(categories));
  yield put(setContentAssemblerKey(contentAssemblerKey + 1));

  global.showToast({
    duration: 3000,
    message: "ContentUpdated",
    variant: "success",
  });
}

export function* deleteContent(params) {
  params = get(params, "payload.params");
  console.log({ params });
  const user = yield select(selectorUser);
  const categories = yield select(selectorCategories);
  const contentAssemblerKey = yield select(selectorContentAssemblerKey);

  const junkFile = params.content.data;
  const result = yield call(
    DeleteContent,
    user?.accessToken,
    params.content.id
  );
  console.log({ result });
  if (result == "done") {
    const subIndex = categories?.sub?.findIndex(
      (e) => e.id == params.categoryId
    );
    categories?.sub[subIndex]?.contents?.splice(params.index, 1);
  }

  yield put(getCategoriesResponse(categories));
  yield put(setContentAssemblerKey(contentAssemblerKey + 1));

  if (junkFile?.includes("http")) {
    //delete junk file from storage
    const junkResult = yield call(DeleteFile, user?.accessToken, junkFile);
    console.log({ junkResult });
  }
}
