import React from "react";
import PropTypes from "prop-types";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";

TextComponent.propTypes = {
  /**text title */
  title: PropTypes.string.isRequired,
  /**text body */
  body: PropTypes.string.isRequired,
  /**is admin or not */
  admin: PropTypes.bool,
  /**onDelete item function  */
  onDelete: PropTypes.bool,
  /**onEdit item function  */
  onEdit: PropTypes.bool,
};

export default function TextComponent(props) {
  const { title, body, admin, onDelete, onEdit } = props;
  return (
    <div className="flex flex-col relative w-full mb-8">
      <div className="flex items-center justify-between">
        <p className="text-black  font-sans font-bold mb-1">{title}</p>
        {admin && (
          <div className="flex items-center justify-end">
            <AiFillEdit
              onClick={onEdit}
              className="text-lg mr-3 cursor-pointer"
            />
            <MdDelete onClick={onDelete} className="text-lg cursor-pointer" />
          </div>
        )}
      </div>
      <p className="text-black text-base font-sans">{body}</p>
    </div>
  );
}
