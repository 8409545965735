import { get } from "lodash/fp";

//General
export const selectorContentAssemblerKey = get([
  "general",
  "contentAssemblerKey",
]);

export const selectorPublicData = get(["general", "publicData"]);

//Classes
export const selectorClassesContent = get(["classes", "classesContent"]);

//Category
export const selectorCategories = get(["category", "categories"]);

//Workshops
export const selectorWorkshopsContent = get(["workshops", "workshopsContent"]);

//Blog
export const selectorBlogContent = get(["blog", "blogContent"]);

//Favorite
export const selectorFavorites = get(["favorite", "favorites"]);

//Downloads
export const selectorDownloads = get(["download", "downloads"]);

//Profile
export const selectorUser = get(["user", "user"]);
export const selectorTeacher = get(["user", "teacher"]);
export const selectorIsTeacherOrAdmin = get(["user", "isTeacherOrAdmin"]);

//Complete
export const selectorCompletes = get(["complete", "completes"]);

//Chat
export const selectorChatThreads = get(["chat", "chatThreads"]);
export const selectorChatMessages = get(["chat", "chatMessages"]);

//Payment
export const selectorPaymentData = get(["payment", "paymentData"]);
