import { call, select, put } from "@redux-saga/core/effects";
import { get, isEmpty } from "lodash";
import moment from "moment";
import {
  CreateCategory,
  DeleteCategory,
  GetAllCategories,
  UpdateCategory,
} from "../../../API/category/Category";
import { UploadFile } from "../../../API/storage/Storage";
import { UndefineCleaner } from "../../../utils/js";
import { getCategoriesResponse } from "../../action/category.action";
import { selectorCategories, selectorUser } from "../../store/selectors";

export function* getCategories() {
  const user = yield select(selectorUser);
  const categories = yield call(GetAllCategories, user?.accessToken);
  console.log({ categories });
  const head = [],
    cat = [],
    sub = [];
  for (const item of categories) {
    if (item.type == "head") head.unshift(item);
    else if (item.type == "cat") cat.unshift(item);
    else sub.unshift(item);
  }
  yield put(getCategoriesResponse({ head, cat, sub }));
}

export function* setCategory(params) {
  params = get(params, "payload.params");
  const { noSubCats, pickedImage, ...otherParams } = params;

  console.log({ noSubCats, pickedImage, ...otherParams });
  const user = yield select(selectorUser);
  const categories = yield select(selectorCategories);
  let head = [...categories.head],
    cat = [...categories.cat],
    sub = [...categories.sub];

  let obj = { ...otherParams };

  //upload image
  if (!isEmpty(pickedImage)) {
    const imageResult = yield call(UploadFile, user.accessToken, {
      file: pickedImage.file,
      ref: `category/${moment().unix()}`,
      mime: pickedImage.mime,
    });
    obj.image = imageResult.downloadUrl;
  }

  if (otherParams.id) {
    //update category
    const result = yield call(
      UpdateCategory,
      user.accessToken,
      otherParams.id,
      UndefineCleaner(obj)
    );
    if (result == "done") {
      //update redux
      if (otherParams.type == "head") {
        const headIndex = head?.findIndex((e) => e.id == otherParams.id);
        head[headIndex] = obj;
      } else if (otherParams.type == "cat") {
        const catIndex = cat?.findIndex((e) => e.id == otherParams.id);
        cat[catIndex] = obj;
      } else {
        const subIndex = sub?.findIndex((e) => e.id == otherParams.id);
        sub[subIndex] = obj;
      }
    }
  } else {
    //create category

    //send data to server
    const result = yield call(
      CreateCategory,
      user.accessToken,
      UndefineCleaner(obj)
    );

    //update redux
    if (otherParams.type == "head") head.unshift(result);
    else if (otherParams.type == "cat") cat.unshift(result);
    else sub.unshift(result);
  }
  yield put(getCategoriesResponse({ head, cat, sub }));
}

export function* deleteCategory(params) {
  params = get(params, "payload.params");
  const user = yield select(selectorUser);
  const categories = yield select(selectorCategories);
  const { id, type } = params,
    junkFiles = [];
  let head = [...categories.head],
    cat = [...categories.cat],
    sub = [...categories.sub];

  // delete category from server
  const result = yield call(DeleteCategory, user?.accessToken, id);

  if (result == "done") {
    switch (type) {
      case "head":
        head = head.filter((e) => e.id != id);
        break;
      case "cat":
        cat = cat.filter((e) => e.id != id);
        break;
      case "sub":
        sub = sub.filter((e) => e.id != id);
        break;

      default:
        break;
    }

    yield put(getCategoriesResponse({ head, cat, sub }));

    //delete all junk files from server
  }
}
