import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import i18n from "../../locale/I18";
import {
  deleteCategoryRequest,
  setCategoryRequest,
} from "../../redux/action/category.action";
import {
  selectorCategories,
  selectorCompletes,
  selectorIsTeacherOrAdmin,
  selectorUser,
} from "../../redux/store/selectors";
import SubCatCard from "./SubCatCard";
import { MdAddCircleOutline } from "react-icons/md";
import CategoryModal from "./CategoryModal";
import { MdOutlineMenu } from "react-icons/md";
import { useHistory } from "react-router-dom";

function CategoryRenderer(props) {
  const {
    head,
    isTeacherOrAdmin,
    categories,
    user,
    setCategory,
    completes,
    paymentData,
    deleteCategory,
  } = props;
  const [cats, setCats] = useState([]);
  const [categoryModal, setCategoryModal] = useState({
    active: false,
    data: null,
    type: null,
    categoryId: null,
  });

  const history = useHistory();

  const onEditCategory = (item) => {
    setCategoryModal({
      active: true,
      data: item,
      type: item.type,
      categoryId: item.categoryId,
    });
  };
  const onDeleteCategory = (item) => {
    deleteCategory({ id: item.id, type: item.type });
  };

  const onSaveCategory = async (data) => {
    setCategory(data);
  };

  const onConfirmDeleteCategory = (item) => {
    global.showConfirmAlert({
      title: i18n.t("Warning"),
      message:
        item.type == "cat"
          ? i18n.t("SureDeleteCategory")
          : i18n.t("SureDeleteSubCategory"),
      confirm: i18n.t("Delete"),
      //   confirmButtonStyle: GlobalStyle.error,
      //   confirmTextStyle: GlobalStyle.textWhite,
      onConfirm: () => onDeleteCategory(item),
    });
  };

  const prepareCategories = () => {
    const catsArr = categories?.cat?.filter((e) => e.categoryId == head.id);
    setCats(catsArr);
  };
  useEffect(() => {
    if (!isEmpty(head)) prepareCategories();
  }, [head, categories]);

  console.log({ cats });

  const onPressAddCategory = () => {
    setCategoryModal({
      active: true,
      data: {},
      type: "cat",
      categoryId: head.id,
    });
  };

  const onPressAddSubCategory = (id) => {
    setCategoryModal({
      active: true,
      data: {},
      type: "sub",
      categoryId: id,
    });
  };

  const onClickCategoryMenu = (item) => {
    const menuItems = [
      {
        title: i18n.t("Edit"),
        onClick: () => {
          onEditCategory(item);
        },
      },
      {
        title: i18n.t("Delete"),
        onClick: () => {
          onConfirmDeleteCategory(item);
        },
      },
    ];
    global.showMenu({
      items: menuItems,
      menuTitle: `Do you need any action on ${
        item.type == "cat" ? "category" : "subcategory"
      } of ${item.title}?`,
    });
  };

  const onClickSubCategory = (item, isComplete) => {
    history.push("tutorial", {
      params: {
        data: item,
        isComplete,
      },
    });
  };

  const renderSub = (subCats, categoryId) => {
    return (
      <div className="flex flex-wrap items-center">
        {isTeacherOrAdmin && (
          <div
            className="w-40 h-48 rounded-md shadow-sm flex flex-col relative p-2 bg-gray-200 mb-4 mr-4 cursor-pointer items-center justify-center"
            onClick={() => {
              onPressAddSubCategory(categoryId);
            }}
          >
            <MdAddCircleOutline size={"1.25rem"} />
          </div>
        )}
        {subCats?.map((item, index) => {
          const isComplete =
            completes?.findIndex((e) => e.categoryId == item.id) !== -1;

          return (
            <SubCatCard
              data={item}
              isTeacherOrAdmin={isTeacherOrAdmin}
              isComplete={isComplete}
              onClickMenu={() => {
                onClickCategoryMenu(item);
              }}
              onClick={() => {
                onClickSubCategory(item, isComplete);
              }}
            />
          );
        })}
      </div>
    );
  };

  const renderCat = () => {
    return (
      <div>
        {cats?.map((item, index) => {
          const subCats = categories?.sub?.filter(
            (e) => e.categoryId == item.id
          );

          return (
            <div>
              <div className="flex items-center mb-4">
                <p className="text-lg font-semibold ">{item?.title}</p>
                {isTeacherOrAdmin && (
                  <MdOutlineMenu
                    onClick={() => {
                      onClickCategoryMenu(item);
                    }}
                    size={"1rem"}
                    className={`mt-1 ml-2 cursor-pointer`}
                  />
                )}
              </div>

              {renderSub(subCats, item.id)}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="w-full px-4 flex flex-col">
      {isTeacherOrAdmin && (
        <div
          className="flex w-max items-center  cursor-pointer mb-2"
          onClick={onPressAddCategory}
        >
          <MdAddCircleOutline size={"1.25rem"} />
          <p className="text-lg font-semibold ml-2">{i18n.t("AddCategory")}</p>
        </div>
      )}
      {renderCat()}

      <CategoryModal
        active={categoryModal.active}
        data={categoryModal.data}
        type={categoryModal.type}
        categoryId={categoryModal.categoryId}
        onSave={(data) => {
          onSaveCategory(data);
        }}
        onClose={() => {
          setCategoryModal({
            active: false,
            data: null,
            type: null,
            categoryId: null,
          });
        }}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  isTeacherOrAdmin: selectorIsTeacherOrAdmin(state),
  user: selectorUser(state),
  categories: selectorCategories(state),
  completes: selectorCompletes(state),
});

const mapDispatchToProps = {
  setCategory: setCategoryRequest,
  deleteCategory: deleteCategoryRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryRenderer);
