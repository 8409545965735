import axios from "axios";
import { isEmpty } from "lodash";
import { ServerAPI, VERSION } from "../../global/Config";
import {
  getUserDataResponse,
  removeUserDataRequest,
} from "../../redux/action/user.action";
import { store } from "../../redux/store";
import { HandleError } from "../../utils/error/Error";
import { WelcomeEmail } from "../email/Email";
import { GetMyCurrentSubscription } from "../subscription/Subscription";

const URL = `${ServerAPI}/${VERSION}/auth`;

export const Signup = async (params) => {
  const context = "auth @Signup";

  try {
    const result = await axios.post(`${URL}/signup`, params);
    const response = result.data;
    console.log({ response });
    if (response) {
      console.log({ response });

      store.dispatch(getUserDataResponse(response));
      global.signIn();
      //   navigate("SubscriptionScreen");
      await WelcomeEmail({
        email: response.userData.email,
        fullName: response.userData.fullName,
      });
    }
  } catch (error) {
    HandleError(context, error);
  }
};

export const Signin = async (params) => {
  const context = "auth @Signin";
  try {
    const result = await axios.post(`${URL}/signin`, params);
    const response = result.data;
    if (response == "user_not_found") {
      return global.showToast({
        duration: 3000,
        message: "UserNotFound",
        variant: "error",
      });
    }
    if (response == "password_not_match") {
      return global.showToast({
        duration: 3000,
        message: "WrongPassword",
        variant: "error",
      });
    }
    if (response.accessToken) {
      //should check payment data
      if (
        response?.userData?.role == "teacher" ||
        response?.userData?.role == "admin"
      ) {
        store.dispatch(getUserDataResponse(response));
      } else {
        const currentSubscription = await GetMyCurrentSubscription(
          response.accessToken
        );
        if (
          !isEmpty(currentSubscription) &&
          currentSubscription != "no_active_subscription"
        )
          store.dispatch(getUserDataResponse(response));
        else {
          return global.showToast({
            duration: 7000,
            message: "PleaseMakeSubscriptionFirstToUse",
            variant: "error",
          });
        }
      }
    }
  } catch (error) {
    HandleError(context, error);
  }
};

export const CheckAuth = async (email) => {
  const context = "auth @CheckAuth";
  try {
    const result = await axios.get(`${URL}/check/${email}`);
    return result?.data?.authState;
  } catch (error) {
    HandleError(context, error);
  }
};

export const RenewAccess = async (id) => {
  const context = "auth @RenewAccess";

  try {
    const result = await axios.post(`${URL}/renewaccess`, {
      id,
    });
    if (result.data.accessToken)
      store.dispatch(getUserDataResponse(result.data));
    else Signout();
  } catch (error) {
    HandleError(context, error);
    Signout();
  }
};

export const ForgotPassword = async (email) => {
  const context = "auth @ForgotPassword";
  try {
    const result = await axios.get(
      `${URL}/forgotpassword/${email.trim().toLoweCase()}`
    );
    return result?.data;
  } catch (error) {
    HandleError(context, error);
  }
};

export const ChangePassword = async (params, token) => {
  const context = "auth @ChangePassword";
  try {
    const result = await axios.post(`${URL}/changepassword`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result?.data;
  } catch (error) {
    HandleError(context, error);
  }
};

export const Signout = async () => {
  const context = `Auth @Signout`;
  try {
    store.dispatch(removeUserDataRequest());
  } catch (error) {
    HandleError(context, error);
  }
};
