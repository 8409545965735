import { Modal, Slide } from "@mui/material";
import React, { useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { connect } from "react-redux";
import {
  selectorIsTeacherOrAdmin,
  selectorTeacher,
  selectorUser,
} from "../../redux/store/selectors";
import Avatar from "./Avatar";
import ChatComponent from "./ChatComponent";
import ChatThreadComponent from "./ChatThreadComponent";

function ChatModal(props) {
  const { active, onClose, teacher, isTeacherOrAdmin, user } = props;
  const [currentScreen, setCurrentScreen] = useState({
    name: "thread",
    student: null,
    room: null,
  });

  const navigate = (params = { name: "thread", student: null, room: null }) => {
    setCurrentScreen(params);
  };

  return (
    <Modal
      open={active}
      onClose={onClose}
      className="flex items-center md:justify-end justify-center md:mr-4"
      hideBackdrop
    >
      <Slide direction="up" in={active} mountOnEnter unmountOnExit>
        <div
          className={`bg-white w-full max-w-md h-5/6 rounded-lg border-none outline-none shadow-md flex flex-1 flex-col mb-12 md:mb-0 mx-3 md:mx-0`}
        >
          {isTeacherOrAdmin ? (
            <ChatThreadComponent
              onClose={onClose}
              navigate={navigate}
              teacher={teacher}
              onBack={() => {
                navigate({ name: "thread", student: null, room: null });
              }}
              user={user}
              currentScreen={currentScreen}
            />
          ) : (
            <ChatComponent onClose={onClose} teacher={teacher} user={user} />
          )}
        </div>
      </Slide>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  user: selectorUser(state),
  teacher: selectorTeacher(state),
  isTeacherOrAdmin: selectorIsTeacherOrAdmin(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ChatModal);
