import React from "react";
import PropTypes from "prop-types";
import ImageComponent from "./ImageComponent";
import VideoComponent from "./VideoComponent";
import AudioComponent from "./AudioComponent";
import TextComponent from "./TextComponent";

ContentAssembler.propTypes = {
  /**content data */
  data: PropTypes.object.isRequired,
  /**content onPress */
  onPress: PropTypes.func.isRequired,
  /**Active or not */
  active: PropTypes.bool,
  /**is admin or not */
  admin: PropTypes.bool,
  /**onDelete content */
  onDeleteContent: PropTypes.func,
  /**onEdit content */
  onEditContent: PropTypes.func,
};

export default function ContentAssembler(props) {
  const { onEditContent, onDeleteContent, data, admin, onPressOpenModal } =
    props;
  const OnDeleteContent = async (content, index) => {
    onDeleteContent(content, index);
  };

  const OnEditContent = async (item, index) => {
    onEditContent(item, index);
  };

  console.log({ data });

  const renderContent = (tutorialData, onPressOpenModal) => {
    return tutorialData.map((item, index) => {
      if (item?.type === "image") {
        // const image =
        //   from === 'downloads'
        //     ? Platform.OS === 'android'
        //       ? `file://${item?.data?.image}`
        //       : item?.data?.image
        //     : item?.data?.image;
        return (
          <ImageComponent
            title={item?.title}
            image={item?.data}
            admin={admin}
            onDelete={() => OnDeleteContent(item, index)}
            onEdit={() => OnEditContent(item, index)}
          />
        );
      } else if (item?.type === "video") {
        // const video =
        //   from === 'downloads'
        //     ? Platform.OS === 'android'
        //       ? `file://${item?.data?.video}`
        //       : item?.data?.video
        //     : item?.data?.video;
        return (
          <VideoComponent
            title={item?.title}
            video={item?.data}
            // time={item?.data?.time}
            onPressOpenModal={(data) => onPressOpenModal(data)}
            admin={admin}
            onDelete={() => OnDeleteContent(item, index)}
            onEdit={() => OnEditContent(item, index)}
          />
        );
      } else if (item?.type === "audio") {
        // const audio =
        //   from === 'downloads'
        //     ? Platform.OS === 'android'
        //       ? `file://${item?.data?.audio}`
        //       : item?.data?.audio
        //     : item?.data?.audio;
        return (
          <AudioComponent
            title={item?.title}
            audio={item?.data}
            admin={admin}
            onDelete={() => OnDeleteContent(item, index)}
            onEdit={() => OnEditContent(item, index)}
          />
        );
      } else if (item?.type === "text") {
        return (
          <TextComponent
            title={item?.title}
            body={item?.data}
            admin={admin}
            onDelete={() => OnDeleteContent(item, index)}
            onEdit={() => OnEditContent(item, index)}
          />
        );
      }
    });
  };
  return (
    <div className="flex flex-col w-full">
      {renderContent(data, onPressOpenModal)}
    </div>
  );
}
