// import { GlobalStyle } from "../style/GlobalStyle";

export function HandleError(context, error) {
  console.log(`ErrorHandler ${context} ===> `, error);
  switch (error.code) {
    case "auth/wrong-password":
      global.showToast({
        duration: 3000,
        message: "WrongPassword",
        variant: "error",
      });
      break;
    case "auth/invalid-email":
      global.showToast({
        duration: 3000,
        message: "InvalidEmail",
        variant: "error",
      });
      break;
    case "auth/user-disabled":
      break;
    case "auth/user-not-found":
      global.showToast({
        duration: 3000,
        message: "UserNotFound",
        variant: "error",
      });
      break;
    case "auth/network-request-failed":
      console.log("network-request-failed");
      break;
    case "auth/email-already-in-use":
      global.showToast({
        duration: 3000,
        message: "EmailInUse",
        variant: "error",
      });
      break;
    case "auth/operation-not-allowed":
      console.log("auth/operation-not-allowed");
      break;
    case "auth/weak-password":
      console.log("auth/weak-password");
      break;
    case "auth/account-exists-with-different-credential":
      console.log("auth/account-exists-with-different-credential");
      break;
    case "auth/invalid-credential":
      console.log("auth/invalid-credential");
      break;
    case "auth/operation-not-allowed":
      console.log("auth/operation-not-allowed");
      break;

    default:
      console.log({ error: error });
      break;
  }
}
