import React from "react";
import PropTypes from "prop-types";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";

ImageComponent.propTypes = {
  /**ImageComponent image */
  image: PropTypes.string.isRequired,
  /**ImageComponent title */
  title: PropTypes.string.isRequired,
  /**is admin or not */
  admin: PropTypes.bool,
  /**onDelete item function  */
  onDelete: PropTypes.bool,
  /**onEdit item function  */
  onEdit: PropTypes.bool,
};

export default function ImageComponent(props) {
  const { title, image, admin, onDelete, onEdit } = props;

  return (
    <div className="flex flex-col relative w-full mb-8">
      <div className="flex items-center justify-between">
        <p className="text-black  font-sans font-bold mb-1">{title}</p>
        {admin && (
          <div className="flex items-center justify-end">
            <AiFillEdit
              onClick={onEdit}
              className="text-lg mr-3 cursor-pointer"
            />
            <MdDelete onClick={onDelete} className="text-lg cursor-pointer" />
          </div>
        )}
      </div>
      <div className="flex items-center justify-center w-full h-80 rounded-lg bg-black">
        <img src={image} className="h-full object-contain " />
      </div>
    </div>
  );
}
