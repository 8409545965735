import {
  CREATE_FAVORITE_REQUEST,
  DELETE_FAVORITE_REQUEST,
  GET_FAVORITES_REQUEST,
  GET_FAVORITES_RESPONSE,
} from '../constant/types';

export const getFavoritesRequest = params => ({
  type: GET_FAVORITES_REQUEST,
  payload: {params},
});

export const getFavoritesResponse = params => ({
  type: GET_FAVORITES_RESPONSE,
  payload: {params},
});

export const createFavoriteRequest = params => ({
  type: CREATE_FAVORITE_REQUEST,
  payload: {params},
});
export const deleteFavoriteRequest = params => ({
  type: DELETE_FAVORITE_REQUEST,
  payload: {params},
});
