import {CURRENT_USER} from '../constant/types';

const initialState = {
  currentUser: {},
};

export default (state = initialState, action = {}) => {
  const {payload, type} = action;
  switch (type) {
    // Manage setIsLoadingRequest action to change loading status into store
    case CURRENT_USER:
      return {
        ...state,
        currentUser: {...payload.params},
      };

    default:
      return state;
  }
};
