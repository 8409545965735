import React from "react";
import { HiBadgeCheck } from "react-icons/hi";
import { MdOutlineMenu } from "react-icons/md";

export default function SubCatCard(props) {
  const {
    data,
    button,
    isComplete = false,
    isTeacherOrAdmin = false,
    onClickMenu,
    onClick,
  } = props;
  return (
    <div
      className="w-40 h-48 rounded-md shadow-sm flex flex-col relative p-2 bg-gray-200 mb-4 mr-4 cursor-pointer"
      onClick={onClick}
    >
      <img src={data.image} className="rounded-md w-40 h-32" />
      <div className="flex items-center">
        <p className="line-clamp-2 w-full">{data?.title}</p>
        {isComplete && <HiBadgeCheck className="m-px" />}
      </div>
      {button}
      {isTeacherOrAdmin && (
        <div
          className="bg-white w-6 h-6 rounded-full flex items-center justify-center absolute shadow-md z-20 top-3 right-3"
          onClick={(e) => {
            e.stopPropagation();
            onClickMenu();
          }}
        >
          <MdOutlineMenu size={"1rem"} />
        </div>
      )}
    </div>
  );
}
