import React, { useEffect, useState } from "react";
import i18n from "../../locale/I18";
import { RiSearch2Line } from "react-icons/ri";
import { IoMdPerson } from "react-icons/io";
import { VscHome } from "react-icons/vsc";
import { FiMessageCircle } from "react-icons/fi";
import { useHistory, useLocation } from "react-router";
import { connect } from "react-redux";
import { selectorIsTeacherOrAdmin } from "../../redux/store/selectors";
import ChatModal from "./ChatModal";

function Navbar(props) {
  const { isTeacherOrAdmin } = props;
  const [activeRoute, setActiveRoute] = useState("");
  const [activeChatModal, setActiveChatModal] = useState(false);

  const homePathes = ["/", "/tutorial"];
  const searchPathes = ["/search"];
  const chatPathes = ["/liveChat", "/chatThreads"];
  //   const profilePathes = ["/profile"];

  const location = useLocation();
  let pathName = location.pathname;

  const history = useHistory();

  useEffect(() => {
    checkActiveRoute();
  }, [pathName]);

  const checkActiveRoute = () => {
    if (homePathes.includes(pathName)) {
      setActiveRoute("home");
    } else if (searchPathes.includes(pathName)) {
      setActiveRoute("search");
    } else if (chatPathes.includes(pathName)) {
      setActiveRoute("chat");
    } else {
      setActiveRoute("profile");
    }
  };

  return (
    <div className="w-full  bg-white h-14 flex justify-center fixed bottom-0 md:top-0 z-10 shadow-md">
      <div className="w-full max-w-5xl flex justify-between items-center">
        <div className="h-full w-2/6 hidden justify-center items-center md:flex">
          <p className="text-center text-black text-3xl font-logo-light">
            {i18n.t("AppName")}
          </p>
        </div>
        <div className="h-full w-full md:w-4/5 flex justify-around items-center">
          <VscHome
            onClick={() => {
              if (pathName !== "/") history.push("/");
            }}
            className={`text-2xl text-gray-300 cursor-pointer ${
              activeRoute == "home" && "nvbr-active"
            }`}
          />

          <RiSearch2Line
            onClick={() => {
              if (pathName !== "/search") history.push("/search");
            }}
            className={`text-2xl text-gray-300 cursor-pointer ${
              activeRoute == "search" && "nvbr-active"
            }`}
          />

          <FiMessageCircle
            onClick={() => {
              setActiveChatModal(true);
            }}
            className={`text-2xl text-gray-300 cursor-pointer ${
              activeRoute == "chat" && "nvbr-active"
            }`}
          />

          <IoMdPerson
            onClick={() => {
              if (pathName !== "/profile") history.push("/profile");
            }}
            className={`text-2xl text-gray-300 cursor-pointer ${
              activeRoute == "profile" && "nvbr-active"
            }`}
          />
        </div>
      </div>

      <ChatModal
        active={activeChatModal}
        onClose={() => {
          setActiveChatModal(false);
        }}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  isTeacherOrAdmin: selectorIsTeacherOrAdmin(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
