import {
  INITIAL_CALLS_LOGGED_IN_REQUEST,
  INITIAL_CALLS_REQUEST,
} from "../constant/types";

export const initialCallRequest = (params) => ({
  type: INITIAL_CALLS_REQUEST,
  payload: { params },
});

export const initialCallLoggedInRequest = (params) => ({
  type: INITIAL_CALLS_LOGGED_IN_REQUEST,
  payload: { params },
});
