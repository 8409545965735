import axios from "axios";
import { ServerAPI, VERSION } from "../../global/Config";
import { HandleError } from "../../utils/error/Error";

const URL = `${ServerAPI}/${VERSION}/storage`;

export const UploadFile = async (token, params) => {
  const context = "Storage @UploadFile";
  try {
    const form = new FormData();
    form.append("file", params.file, params.file.name);
    form.append("ref", params.ref);
    const response = await axios.post(URL, form, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    HandleError(context, error);
  }
};
export const DeleteFile = async (token, ref) => {
  const context = "Storage @DeleteFile";
  try {
    const result = await axios.delete(`${URL}`, {
      headers: { Authorization: `Bearer ${token}` },
      data: { ref },
    });
    return result.data;
  } catch (error) {
    HandleError(context, error);
  }
};
