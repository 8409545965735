import { isEmpty } from "lodash";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import BaseView from "../../components/common/BaseView";
import SearchInput from "../../components/common/SearchInput";
import SubCatCard from "../../components/home/SubCatCard";
import i18n from "../../locale/I18";
import {
  selectorCategories,
  selectorCompletes,
  selectorIsTeacherOrAdmin,
  selectorPaymentData,
} from "../../redux/store/selectors";

function SearchScreen(props) {
  const { categories, completes, isTeacherOrAdmin, paymentData } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const history = useHistory();

  const onSearch = (text) => {
    const result = [];
    categories?.sub?.map((e) => {
      if (e.title?.toLowerCase()?.includes(text.toLowerCase())) {
        result.push(e);
      } else {
        e.contents.map((c) => {
          if (
            c?.title?.toLowerCase()?.includes(text) ||
            c.data?.includes(text.toLowerCase())
          ) {
            if (!result.includes(e)) result.push(e);
          }
        });
      }
    });
    setSearchResult(result);
  };

  console.log({ searchResult });

  const onClear = () => {
    setSearchResult([]);
    setSearchTerm("");
  };

  const onClickSubCategory = (item, isComplete) => {
    if (paymentData || isTeacherOrAdmin) {
      history.push("tutorial", {
        params: {
          data: item,
          isComplete,
        },
      });
    } else {
    }
  };

  return (
    <BaseView>
      <div className="h-full w-full p-4">
        <SearchInput
          placeholder={i18n.t("SearchByGoals")}
          onTextChange={(text) => {
            setSearchTerm(text);
            if (text.length >= 3) onSearch(text);
          }}
          value={searchTerm}
          onClear={onClear}
        />
        <div>
          {isEmpty(searchResult) ? (
            <div className="flex flex-col items-center p-4">
              <p className="text-3xl font-bold text-center text-gray-400 mb-4">
                {i18n.t("DiscoverItems")}
              </p>
              <p className="text-xl text-center text-gray-400">
                {i18n.t("DiscoverItemsDesc")}
              </p>
            </div>
          ) : (
            <div className=" w-full flex flex-wrap items-center py-4 ">
              {searchResult.map((item, index) => {
                const isComplete =
                  completes?.findIndex((e) => e.categoryId == item.id) !== -1;

                return (
                  <SubCatCard
                    data={item}
                    isComplete={isComplete}
                    onClick={() => {
                      onClickSubCategory(item, isComplete);
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </BaseView>
  );
}

const mapStateToProps = (state) => ({
  categories: selectorCategories(state),
  completes: selectorCompletes(state),
  isTeacherOrAdmin: selectorIsTeacherOrAdmin(state),
  paymentData: selectorPaymentData(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SearchScreen);
