import axios from "axios";
import { ServerAPI, VERSION } from "../../global/Config";
import { HandleError } from "../../utils/error/Error";

const URL = `${ServerAPI}/${VERSION}/email`;

export const EmailVerification = async (params) => {
  try {
    const response = await axios.post(`${URL}/verification`, params);
    return response.data;
  } catch (error) {
    const context = `Email @EmailVerification`;
    HandleError(context, error);
  }
};

export const WelcomeEmail = async (params) => {
  try {
    const response = await axios.post(`${URL}/welcome`, params);
    return response.data;
  } catch (error) {
    const context = `Email @WelcomeEmail`;
    HandleError(context, error);
  }
};
