import {
  GET_TEACHER_DATA_REQUEST,
  GET_TEACHER_DATA_RESPONSE,
  GET_USER_DATA_REQUEST,
  GET_USER_DATA_RESPONSE,
  REMOVE_USER_DATA_REQUEST,
  SET_IS_TEACHER_OR_ADMIN,
  SET_USER_DATA_REQUEST,
  UPDATE_TEACHER_REQUEST,
} from "../constant/types";

export const getTeacherDataRequest = (params) => ({
  type: GET_TEACHER_DATA_REQUEST,
  payload: { params },
});

export const getTeacherDataResponse = (params) => ({
  type: GET_TEACHER_DATA_RESPONSE,
  payload: { params },
});

export const getUserDataRequest = (params) => ({
  type: GET_USER_DATA_REQUEST,
  payload: { params },
});

export const getUserDataResponse = (params) => ({
  type: GET_USER_DATA_RESPONSE,
  payload: { params },
});

export const setUserDataRequest = (params) => ({
  type: SET_USER_DATA_REQUEST,
  payload: { params },
});

export const removeUserDataRequest = (params) => ({
  type: REMOVE_USER_DATA_REQUEST,
  payload: { params },
});

export const updateTeacherRequest = (params) => ({
  type: UPDATE_TEACHER_REQUEST,
  payload: { params },
});

export const setIsTeacherOrAdmin = (params) => ({
  type: SET_IS_TEACHER_OR_ADMIN,
  payload: { params },
});
