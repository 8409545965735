import React, { useEffect } from "react";
import { connect } from "react-redux";
import ConfirmAlert from "../components/common/ConfirmAlert";
import CustomMenu from "../components/common/CustomMenu";
import ToastComponent from "../components/common/ToastComponent";
import Navigation from "../navigation/Navigation";
import { initialCallRequest } from "../redux/action/initial.action";
import { getUserDataResponse } from "../redux/action/user.action";

function RootContainer(props) {
  const { initialCall, getUserResponse } = props;
  useEffect(() => {
    initialCall();
    window.addEventListener("unload", persistCleaner);
    return () => {
      window.removeEventListener("unload", persistCleaner);
    };
  }, []);

  const persistCleaner = () => {
    getUserResponse({});
  };

  return (
    <div className="h-screen w-full">
      <Navigation />
      <ToastComponent />
      <ConfirmAlert />
      <CustomMenu />
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  initialCall: initialCallRequest,
  getUserResponse: getUserDataResponse,
};

export default connect(mapStateToProps, mapDispatchToProps)(RootContainer);
