const initialState = {};

export default (state = initialState, action = {}) => {
  const {payload, type} = action;
  switch (type) {
    // Manage setIsLoadingRequest action to change loading status into store
    default:
      return state;
  }
};
