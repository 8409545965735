import React, { useEffect, useState } from "react";
import BaseView from "../../components/common/BaseView";
import TopBackground from "../../assets/image/profile/TopBackGround.png";
import avatar from "../../assets/image/profile/avatar.png";
import { connect } from "react-redux";
import {
  selectorIsTeacherOrAdmin,
  selectorPublicData,
  selectorUser,
} from "../../redux/store/selectors";
import { isEmpty } from "lodash";
import { BiEdit } from "react-icons/bi";
import { FiInstagram, FiChevronRight } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import i18n from "../../locale/I18";
import InputModal from "../../components/profile/InputModal";
import { setUserDataRequest } from "../../redux/action/user.action";
import { useHistory } from "react-router";
import { Signout } from "../../API/auth/Auth";
import EditProfileModal from "../../components/profile/EditProfileModal";
import Avatar from "../../components/common/Avatar";

function ProfileScreen(props) {
  const { user, updateProfile, isTeacherOrAdmin, publicData } = props;
  const [instagram, setInstagram] = useState("");
  const [editProfileModal, setEditProfileModal] = useState(false);
  const [inputModal, setInputModal] = useState({
    active: false,
    text: "",
    title: "",
    onSave: () => {},
    name: "",
  });
  const history = useHistory();

  useEffect(() => {
    if (!isEmpty(user)) {
      setInstagram(user?.userData?.instagram);
    }
  }, [user]);

  const onPressFavorite = () => {
    history.push("/profile/favorite");
  };

  const onPressSettings = () => {
    history.push("/profile/settings");
  };
  const onPressHelp = () => {
    window.open(publicData?.helpUrl, "_blank");
  };
  const onSignOut = async () => {
    await Signout();
  };

  const onSaveInstagram = async (text) => {
    const socialMedia = user.socialMedia || [];
    const instagramIndex = socialMedia?.findIndex(
      (e) => e.title === "instagram"
    );
    if (instagramIndex !== -1) {
      socialMedia[instagramIndex].address = `https://instagram.com/${text}`;
    } else {
      const instagramObj = {
        title: "instagram",
        address: `https://instagram.com/${text}`,
      };
      socialMedia.push(instagramObj);
    }
    user.socialMedia = socialMedia;
    await updateProfile(user);
  };

  const onEditProfile = () => {
    setEditProfileModal(true);
  };

  const onEditInstagram = () => {
    const instagram = user?.socialMedia?.find(
      (sm) => sm.title === "instagram"
    ).address;
    const instagramID = instagram?.split("/")?.pop();
    toggleInputModal(
      true,
      instagramID,
      i18n.t("EnterYourInstagram"),
      onSaveInstagram
    );
  };

  const onEditName = () => {
    toggleInputModal(
      true,
      [user.firstName, user.lastName],
      i18n.t("EnterYourName"),
      onSaveName,
      true
    );
  };

  const onSaveName = async (nameArray) => {
    user.firstName = nameArray[0];
    user.lastName = nameArray[1];
    await updateProfile(user);
  };

  let buttons = [
    { title: "Favorites", onClick: onPressFavorite },
    // { title: "ManageSubscriptions", onClick: onPressSubscription },
    // { title: "Notifications", onClick: onPressNotifications },
    { title: "Help", onClick: onPressHelp },
  ];

  if (isTeacherOrAdmin)
    buttons = [
      { title: "Favorites", onClick: onPressFavorite },
      // { title: "ManageSubscriptions", onClick: onPressSubscription },
      // { title: "Notifications", onClick: onPressNotifications },
      { title: "Settings", onClick: onPressSettings },
      { title: "Help", onClick: onPressHelp },
    ];

  const toggleInputModal = (
    active,
    text = "",
    title = "",
    onSave = () => {},
    name = false
  ) => {
    setInputModal({ active, text, title, onSave, name });
    // setActiveInputModal(active);
    // setInputModalText(text);
    // setInputModalTitle(title);
    // setInputModalOnSave(onSave);
    // setInputModalName(name);
  };

  const onChooseImage = async () => {
    var input = document.createElement("input");
    input.type = "file";
    input.accept = "image/png, image/jpeg, image/jpg";
    input.click();
    input.addEventListener("input", uploadImage);
  };

  const uploadImage = async (e) => {
    global.showToast({
      duration: 3000,
      message: "PleaseWait",
      variant: "info",
    });
    const pickedImage = e.target.files[0];
    const url = URL.createObjectURL(pickedImage);
    const ref = `profileImages/}`;
    // const picture = await Upload({ filePath: url, refrence: ref });
    const picture = {};
    user.photo = picture;
    await updateProfile(user);
    global.showToast({
      duration: 3000,
      message: "ImageProfileUpdated",
      variant: "success",
    });
  };

  return (
    <BaseView>
      <div className="w-full h-full">
        <div className="w-full h-64 relative flex flex-col items-center">
          <img src={TopBackground} className="w-full h-full" />
          <div className="absolute top-0 p-4 flex flex-col  items-center justify-center w-full h-full">
            <Avatar
              src={user?.userData?.photo}
              size="7rem"
              rounded
              className=" -mt-6 shadow-md"
            />
            <div className="flex justify-between items-center md:-mt-6">
              <p>{user?.userData?.fullName}</p>
              <BiEdit
                onClick={onEditProfile}
                className="cursor-pointer mt-0.5 ml-4"
              />
            </div>
          </div>
          <div className="bg-white rounded-lg w-11/12 max-w-md p-2 -mt-10 flex flex-col mb-6">
            <div className="flex items-center">
              <HiOutlineMail className="mr-2" />
              <p>{user?.userData?.email}</p>
            </div>
            <div className="flex items-center w-full relative">
              <FiInstagram className="mr-2" />
              <p>{instagram}</p>
            </div>
          </div>
          <div className="bg-white rounded-lg w-11/12 max-w-md p-2 flex flex-col mb-6">
            {buttons.map((btn, index) => {
              return (
                <div
                  onClick={btn.onClick}
                  className={`flex items-center w-full relative cursor-pointer py-3 px-1 border-gray-200 ${
                    buttons.length == index + 1 ? "border-b-0" : "border-b-2"
                  }`}
                >
                  <p>{i18n.t(btn.title)}</p>
                  <FiChevronRight className="cursor-pointer absolute right-1" />
                </div>
              );
            })}
          </div>
          <div className="bg-white rounded-lg w-11/12 max-w-md p-2 flex flex-col">
            <div
              onClick={onSignOut}
              className={`flex items-center w-full relative cursor-pointer py-3 px-1`}
            >
              <p>{i18n.t("SignOut")}</p>
              <FiChevronRight className="cursor-pointer absolute right-1" />
            </div>
          </div>
        </div>
      </div>

      <InputModal
        active={inputModal.active}
        text={inputModal.text}
        onClose={() => toggleInputModal(false)}
        title={inputModal.title}
        onSave={(text) => inputModal.onSave(text)}
        name={inputModal.name}
      />

      <EditProfileModal
        active={editProfileModal}
        onClose={() => {
          setEditProfileModal(false);
        }}
      />
    </BaseView>
  );
}

const mapStateToProps = (state) => ({
  user: selectorUser(state),
  isTeacherOrAdmin: selectorIsTeacherOrAdmin(state),
  publicData: selectorPublicData(state),
});

const mapDispatchToProps = {
  updateProfile: setUserDataRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreen);
