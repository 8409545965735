import React, { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import i18n from "../../locale/I18";

export default function ToastComponent() {
  const [active, setActive] = useState(false);
  const [message, setMessage] = useState("");
  const [duration, setDuration] = useState(0);
  const [variant, setVariant] = useState("");

  useEffect(() => {
    global.showToast = (params = { message: "", duration: 0, variant: "" }) => {
      setMessage(params.message);
      setDuration(params.duration);
      setVariant(params.variant);
      setActive(true);
    };
  }, []);
  console.log({ variant, message });
  return (
    <Snackbar open={active} autoHideDuration={duration} onClose={() => setActive(false)}>
      <MuiAlert
        onClose={() => setActive(false)}
        variant="filled"
        severity={variant}
        sx={{ width: "100%" }}
      >
        {i18n.t(message)}
      </MuiAlert>
    </Snackbar>
  );
}
