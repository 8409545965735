import React, { useEffect, useRef, useState } from "react";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { MdAddCircleOutline } from "react-icons/md";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";
import BG from "../../assets/image/home/bg.jpeg";
import BaseView from "../../components/common/BaseView";
import CategoryModal from "../../components/home/CategoryModal";
import CategoryRenderer from "../../components/home/CategoryRenderer";
import i18n from "../../locale/I18";
import {
  deleteCategoryRequest,
  setCategoryRequest,
} from "../../redux/action/category.action";
import { initialCallLoggedInRequest } from "../../redux/action/initial.action";
import {
  selectorCategories,
  selectorIsTeacherOrAdmin,
  selectorPaymentData,
  selectorTeacher,
  selectorUser,
} from "../../redux/store/selectors";
import About from "./About";
import { MdOutlineMenu } from "react-icons/md";

function HomeScreen(props) {
  const {
    user,
    isTeacherOrAdmin,
    categories,
    initialCallLoggedIn,
    setCategory,
    paymentData,
    deleteCategory,
    teacher,
  } = props;
  const location = useLocation();
  const history = useHistory();
  const [currentHead, setCurrentHead] = useState({ index: -1, data: {} });
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [clientWidth, setClientWidth] = useState(0);
  const [categoryModal, setCategoryModal] = useState({
    active: false,
    data: null,
    type: null,
    categoryId: null,
  });
  const headListRef = useRef(null);

  const onPressHead = (item, index) => {
    setCurrentHead({ index, data: item });
  };

  useEffect(() => {
    initialCallLoggedIn();
    setClientWidth(headListRef?.current?.clientWidth);
  }, []);

  useEffect(() => {
    setScrollWidth(headListRef?.current?.scrollWidth);
  }, [categories]);

  const onSaveCategory = async (data) => {
    setCategory(data);
  };

  const onClickHeadMenu = (item) => {
    const menuItems = [
      {
        title: i18n.t("Edit"),
        onClick: () => {
          setCategoryModal({ active: true, data: item, type: "head" });
        },
      },
      {
        title: i18n.t("Delete"),
        onClick: () => {
          onConfirmDeleteHead(item);
        },
      },
    ];
    global.showMenu({
      items: menuItems,
      menuTitle: `Do you need any action on header of ${item.title}?`,
    });
  };

  const onDeleteHead = (item) => {
    deleteCategory({ id: item.id, type: item.type });
  };

  const onConfirmDeleteHead = (item) => {
    global.showConfirmAlert({
      title: i18n.t("Warning"),
      message: i18n.t("SureDeleteHeader"),
      confirm: i18n.t("Delete"),
      // confirmButtonStyle: GlobalStyle.error,
      // confirmTextStyle: GlobalStyle.textWhite,
      onConfirm: () => onDeleteHead(item),
    });
  };

  const renderHead = () => {
    return (
      <div className="flex items-center">
        <p
          onClick={() => onPressHead({}, -1)}
          className={`text-center mr-4 cursor-pointer text-xl ${
            currentHead.index == -1
              ? "text-black font-semibold"
              : "text-primary-300"
          }`}
        >
          {i18n.t("Home")}
        </p>
        {head?.map((item, index) => {
          return (
            <div className="flex items-center mr-2">
              <p
                onClick={() => onPressHead(item, index)}
                className={`min-w-max text-center cursor-pointer text-xl mx-1 line-clamp-1 ${
                  currentHead.index == index
                    ? "text-black  font-semibold"
                    : "text-primary-300"
                }`}
              >
                {item.title}
              </p>
              {isTeacherOrAdmin && (
                <MdOutlineMenu
                  onClick={() => {
                    onClickHeadMenu(item);
                  }}
                  size={"1rem"}
                  className={`mt-1 mr-1 cursor-pointer ${
                    currentHead.index == index
                      ? "text-black  font-semibold"
                      : "text-primary-300"
                  }`}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  console.log({ categories });

  const onAddHead = () => {
    setCategoryModal({ active: true, data: {}, type: "head" });
  };

  const scroll = (offset) => {
    headListRef.current.scrollLeft += offset;
  };

  console.log({ scrollLeft, scrollWidth, clientWidth });

  let head = categories?.head;
  // head = head.concat(head);
  // head = head.concat(head);
  // head = head.concat(head);
  // head = head.concat(head);
  // head = head.concat(head);
  // head = head.concat(head);

  const bgImage =
    currentHead.index == -1 ? BG : categories?.head[currentHead.index]?.image;

  return (
    <BaseView>
      <div className="w-full h-72 relative mb-3">
        <img
          src={bgImage}
          className="w-full h-72 object-cover absolute top-0 right-0"
        />
        <div className="flex absolute bottom-0  bg-primary-200 bg-opacity-30 h-12 w-full min-w-full align-center ">
          <div
            className={`flex  w-10 items-center justify-center ${
              scrollLeft > 0 ? "cursor-pointer" : ""
            } `}
            onClick={() => {
              scroll(-clientWidth / 3);
            }}
          >
            <AiOutlineDoubleLeft
              size={"1.25rem"}
              className={`${scrollLeft > 0 ? "text-black" : "text-gray-400"}`}
            />
          </div>
          <div
            className="flex w-full overscroll-x-auto overflow-y-hidden no-scrollbar py-1 px-4"
            ref={headListRef}
            onScroll={() => {
              console.log({ headRef: headListRef?.current });
              setScrollLeft(headListRef?.current?.scrollLeft);
            }}
          >
            {isTeacherOrAdmin && (
              <div
                className="h-full  w-8 flex items-center justify-center cursor-pointer mr-4"
                onClick={onAddHead}
              >
                <MdAddCircleOutline size={"1.25rem"} />
              </div>
            )}
            {renderHead()}
          </div>
          <div
            className={`flex  w-10 items-center justify-center ${
              scrollLeft + clientWidth + 5 >= scrollWidth
                ? ""
                : "cursor-pointer"
            } `}
            onClick={() => {
              scroll(clientWidth / 3);
            }}
          >
            <AiOutlineDoubleRight
              onClick={() => {
                scroll(+100);
              }}
              size={"1.25rem"}
              className={`${
                scrollLeft + clientWidth + 5 >= scrollWidth
                  ? "text-gray-400"
                  : "text-black"
              }`}
            />
          </div>
        </div>
      </div>

      {currentHead.index == -1 ? (
        <About />
      ) : (
        <CategoryRenderer head={currentHead.data} />
      )}

      <CategoryModal
        active={categoryModal.active}
        data={categoryModal.data}
        type={categoryModal.type}
        categoryId={categoryModal.categoryId}
        onSave={(data) => {
          onSaveCategory(data);
        }}
        onClose={() => {
          setCategoryModal({
            active: false,
            data: null,
            type: null,
            categoryId: null,
          });
        }}
      />
    </BaseView>
  );
}

const mapStateToProps = (state) => ({
  isTeacherOrAdmin: selectorIsTeacherOrAdmin(state),
  user: selectorUser(state),
  teacher: selectorTeacher(state),
  categories: selectorCategories(state),
  paymentData: selectorPaymentData(state),
});

const mapDispatchToProps = {
  initialCallLoggedIn: initialCallLoggedInRequest,
  setCategory: setCategoryRequest,
  deleteCategory: deleteCategoryRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
