import {GET_CATEGORIES_RESPONSE} from '../constant/types';

const initialState = {
  categories: {
    head: [],
    cat: [],
    sub: [],
  },
};

export default (state = initialState, action = {}) => {
  const {payload, type} = action;
  switch (type) {
    // Manage setIsLoadingRequest action to change loading status into store
    case GET_CATEGORIES_RESPONSE:
      return {
        ...state,
        categories: {...payload.params},
      };

    default:
      return state;
  }
};
