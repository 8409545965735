import { takeLatest } from "redux-saga/effects";
import {
  CREATE_CONTENT_REQUEST,
  CREATE_FAVORITE_REQUEST,
  DELETE_CATEGORY_REQUEST,
  DELETE_CONTENT_REQUEST,
  DELETE_FAVORITE_REQUEST,
  GET_CATEGORIES_REQUEST,
  GET_CHAT_MESSAGES_REQUEST,
  GET_CHAT_THREADS_REQUEST,
  GET_COMPLETES_REQUEST,
  GET_FAVORITES_REQUEST,
  GET_PUBLIC_DATA_REQUEST,
  GET_TEACHER_DATA_REQUEST,
  GET_USER_DATA_REQUEST,
  INITIAL_CALLS_LOGGED_IN_REQUEST,
  INITIAL_CALLS_REQUEST,
  REMOVE_USER_DATA_REQUEST,
  SET_CATEGORY_REQUEST,
  SET_COMPLETE_REQUEST,
  SET_MESSAGE_REQUEST,
  SET_PUBLIC_DATA_REQUEST,
  SET_USER_DATA_REQUEST,
  UPDATE_CONTENT_REQUEST,
  UPDATE_TEACHER_REQUEST,
} from "../constant/types";
import { deleteCategory, getCategories, setCategory } from "./singles/category";
// import { getChatMessages, getChatThreads, setMessage } from "./singles/chat";
import { getComplete, setComplete } from "./singles/complete";
import { createContent, deleteContent, updateContent } from "./singles/content";
import {
  createFavorite,
  deleteFavorite,
  getFavorites,
} from "./singles/favorite";
import { getPublicData, setPublicData } from "./singles/general";
import { initialCall, initialCallLoggedIn } from "./singles/initial";
import {
  getTeacher,
  getUser,
  removeUser,
  setUser,
  updateTeacher,
} from "./singles/user";

export default function* root() {
  //initial call
  yield takeLatest(INITIAL_CALLS_REQUEST, initialCall);
  yield takeLatest(INITIAL_CALLS_LOGGED_IN_REQUEST, initialCallLoggedIn);

  //general
  yield takeLatest(GET_PUBLIC_DATA_REQUEST, getPublicData);
  yield takeLatest(SET_PUBLIC_DATA_REQUEST, setPublicData);

  //category
  yield takeLatest(GET_CATEGORIES_REQUEST, getCategories);
  yield takeLatest(SET_CATEGORY_REQUEST, setCategory);
  yield takeLatest(DELETE_CATEGORY_REQUEST, deleteCategory);

  //content
  yield takeLatest(CREATE_CONTENT_REQUEST, createContent);
  yield takeLatest(UPDATE_CONTENT_REQUEST, updateContent);
  yield takeLatest(DELETE_CONTENT_REQUEST, deleteContent);

  //User and teacher
  yield takeLatest(GET_TEACHER_DATA_REQUEST, getTeacher);
  yield takeLatest(REMOVE_USER_DATA_REQUEST, removeUser);
  yield takeLatest(SET_USER_DATA_REQUEST, setUser);

  //Favorites
  yield takeLatest(GET_FAVORITES_REQUEST, getFavorites);
  yield takeLatest(CREATE_FAVORITE_REQUEST, createFavorite);
  yield takeLatest(DELETE_FAVORITE_REQUEST, deleteFavorite);

  //Complete
  yield takeLatest(GET_COMPLETES_REQUEST, getComplete);
  yield takeLatest(SET_COMPLETE_REQUEST, setComplete);

  // //Chat
  // yield takeLatest(GET_CHAT_MESSAGES_REQUEST, getChatMessages);
  // yield takeLatest(GET_CHAT_THREADS_REQUEST, getChatThreads);
  // yield takeLatest(SET_MESSAGE_REQUEST, setMessage);
}
