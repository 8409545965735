import axios from "axios";
import { ServerAPI, VERSION } from "../../global/Config";
import { HandleError } from "../../utils/error/Error";

const CHATROOM_URL = `${ServerAPI}/${VERSION}/chatroom`;
const MESSAGE_URL = `${ServerAPI}/${VERSION}/message`;

export const GetMyChatsRooms = async (token) => {
  const context = `chat @GetMyChatsRooms`;
  try {
    const result = await axios.get(`${CHATROOM_URL}/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (error) {
    HandleError(context, error);
  }
};

export const CreateChatRoom = async (token, params) => {
  const context = `chat @CreateChatRoom`;
  try {
    const result = await axios.post(`${CHATROOM_URL}`, params, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (error) {
    HandleError(context, error);
  }
};

export const GetRoomMessages = async (token, roomId) => {
  const context = `chat @GetRoomMessages`;
  try {
    const result = await axios.get(`${MESSAGE_URL}/room/${roomId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (error) {
    HandleError(context, error);
  }
};

export const CreateMessage = async (token, params) => {
  const context = `chat @CreateMessage`;
  try {
    const result = await axios.post(`${MESSAGE_URL}`, params, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (error) {
    HandleError(context, error);
  }
};
