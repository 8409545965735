export default {
  AppName: "NAELIVING",
  SignIn: "Sign In",
  SignUp: "Sign Up",
  SignOut: "Sign Out",
  OrConnectWith: "Or connect with",
  DontHaveAccount: "Don't have an account?",
  Email: "Email",
  Password: "Password",
  ConfirmPassword: "Confirm password",
  ForgotPassword: "Forgot Password?",
  CreateNewAccount: "Create new Account",
  FirstName: "First name",
  LastName: "Last name",
  ForgotPasswordTitle: "Forgot Password",
  Next: "Next",
  EnterYourEmail: "Please enter your email",
  ChangePasswordTitle: "Change Password",
  Submit: "Submit",
  SetYourPassword: "Please set your password",
  EmailVerificationTitle: "Email Verification",
  EmailVerificationDesc:
    "Please enter the verification code that was sent to your email",
  ResendOn: "Resend on",
  Resend: "Resend",
  SignUpError: "Error happends in sign up.",
  ShortPassword: "This password is too short, it must be at least 8 characters",
  EmptyField: "Please fill all the fields",
  BadEmail: 'This email is not valid, it must includes "@" and "." ',
  SearchByGoals: "Search by goals",
  Classes: "Classes",
  Workshops: "Workshops",
  Workshop: "Workshop",
  Blog: "Blog",
  About: "About",
  HaveQuestion: "Have a question?",
  HaveQuestionDesc:
    "Feel free to message me and I’ll do my best to get back to you as soon as I can!",
  Copied: "Copied",
  EmailAddressCopied: "Email Address Copied",
  SendAMessage: "Send a Message",
  SocialMedia: "Social Media",
  SocialMediaDesc: "Connect with me on socials",
  Teacher: "Teacher",
  Trainer: "Trainer",
  TapToLearnMore: "Tap to learn more",
  SelectPicture: "Select picture",
  DiscoverClasses: "Discover classess",
  DiscoverClassesDesc:
    "Try discovering new classes with search or browse our categories",
  NoMatchClasses: "No match clasees",
  DiscoverWorkshops: "Discover workshops",
  DiscoverWorkshopsDesc:
    "Try discovering new workshops with search or browse our categories",
  NoMatchWorkshops: "No match workshops",
  DiscoverBlogs: "Discover blogs",
  DiscoverBlogsDesc:
    "Try discovering new blogs with search or browse our categories",
  NoMatchBlogs: "No match blogs",
  TryDifferentSearch: "Try a different search",
  SwipetoComplete: "Complete",
  TakePhoto: "Take a photo",
  ChooseFromGallery: "Choose from gallery",
  Cancel: "Cancel",
  LeaveMeReview: "Leave me a review",
  ShareWithMeOnIstagram: "Share with me on instagram",
  StartTyping: "Start typing ...",
  Send: "Send",
  StartReview: "Start review",
  Downloads: "Downloads",
  ManageSubscriptions: "Manage Subscriptions",
  Notifications: "Notifications",
  Help: "Help",
  Warning: "Warning",
  SureDeleteFavorite: "Are you sure that you want to remove this favorite?",
  SureDeleteDownload: "Are you sure that you want to remove this download?",
  Delete: "Delete",
  YourInstagramId: "Your instagram id",
  EnterYourInstagram: "Enter your instagram id",
  Save: "Save",
  EnterYourName: "Enter your name",
  NoFavoriteMassage: "You don't have any favorite yet!",
  NoDownloadMessage: "You don't have any download yet!",
  New: "New",
  AddCategory: "Add Category",
  AddSubCategory: "Add Subcategory",
  ChooseImage: "Choose image",
  Title: "Title",
  NoSubCats: "No Subcategories",
  Add: "Add",
  Description: "Description",
  TimeDuration: "Time duration",
  Discard: "Discard",
  SureDiscardChanges: "Are you sure that you want to discard changes?",
  SureDeleteCategory: "Are you sure that you want to delete this category?",
  SureDeleteHeader: "Are you sure that you want to delete this header?",
  SureDeleteSubCategory:
    "Are you sure that you want to delete this subcategory?",
  SureDeleteContent: "Are you sure that you want to delete this content?",
  SureDeleteContentYes: "Yes!",
  Update: "Update",
  Skip: "Skip",
  AreYouSureToExit: "Are you sure that you want to complete this tutorial?",
  AreYouSureToExitYes: "Yes!",
  Edit: "Edit",
  Publish: "Publish",
  Upload: "Upload",
  TextPlaceholder: "Write ...",
  ChooseVideo: "Choose Video",
  ChooseAudio: "Choose Audio",
  Audio: "Audio",
  FileName: "File Name",
  AboutPlaceholder: "Type about your self ...",
  ManageSubscriptions: "Manage Subscriptions",
  JoinNow: "Join Now",
  Continue: "Continue",
  Ok: "Ok",
  ChangePasswordEmailSent:
    "An email with change password link has been sent to you!",
  WrongPassword: "Wrong password",
  FillAllFields: "Please fill all the fields",
  UserNotFound: "User not found",
  EmailInUse: "This email already in use please try another one",
  InvalidEmail: "Invalid email address",
  StartingDownload: "Download is starting ...",
  DownloadSuccessful: "Download successful",
  Settings: "Settings",
  SettingsChangeIntroductionVideo: "Video introduction",
  SettingsEditSocials: "Socials",
  VideoBackgroundDesc:
    "This video will be displayed in the first page of application.",
  VideoUploadedSuccessful: "Video uploaded successfully",
  VideoIsUploading: "Video is uploading ...",
  link: "Link",
  NoChatsYet: `You don't have any chat yet!`,
  PleaseWait: "Please wait...",
  ImageProfileUpdated: "Profile image updated.",
  ChangePassword: "Change password",
  CurrentPassword: "Current password",
  NewPassword: "New password",
  Change: "Change",
  AddHeader: "Add Header",
  EditHeader: "Edit Header",
  EditSubCategory: "Edit subcategory",
  EditCategory: "Edit category",
  EmptyHead: "This list is already empty, but it will be complete ASAP",
  NothingToShowYet: "Nothing to show yet, but it will be complete ASAP",
  AddSocialMedia: "Add social media",
  ChooseIcon: "Choose icon",
  PaseYourSocialMediaLinkHere: "Paste your social media link here",
  SureDeleteSocialMedia: "Are you sure that you want delete this social media?",
  discount: "Discount code",
  PromotionButton: "Apply",
  DicountCodeExpired: "Sorry! The discount code is not active!",
  PromotionButtonPlaceholder: "Discount code",
  StartUploading: "Start uploading please wait ...",
  BackgroundVideoUpdated: "Background video updated",
  Review: "Review",
  SendReview: "Send review",
  ThanksForSubmitReview: "Thank for submitting the review",
  DiscountApplied: "40% Special Discount",
  DiscoverItems: "Discover our tutorials",
  DiscoverItemsDesc:
    "Try discovering new tutorials with search or browse our categories",
  UploadDone: "Upload successfully done.",
  ContentUpdated: "Content successfully updated.",
  Previous: "Previous",
  NaelivingTourContent:
    "Welcome to Naeliving. \nYou can follow our courses and tutorials here.",
  SearchTourContent: "You can explore our tutorials here.",
  ProfileTourContent: "You can manage your profile and your information here.",
  NotificationTourContent:
    "You can check your received notifications from teacher here.",
  HeadsTourContent: "You can visit tutorial categories and exploring them here",
  GetStarted: "Get started",
  AlreadyHaveAccount: "Already have an account?",
  EditProfile: "Edit profile",
  PersonalInfo: "Personal info",
  Instagram: "Instagram",
  ProfileUpdated: "Profile successfully updated",
  PasswordChanged: "Password successfully changed",
  SomeThingWentWrong: "Something went wrong, please try again later.",
  CurrentPasswordNotMatch: "Current password is not match",
  YourInstagram: "Your instagram id",
  Home: "Home",
  EmailAddressCopied: "Email address copied to clipboard !",
  Favorites: "Favorites",
  RecentChats: "Recent chats",
  PleaseWait: "Please wait ...",
  PleaseMakeSubscriptionFirstToUse:
    "You don't have an active subscription currently, please download mobile apps from Appstore or Playstore first and choose a subscription and then start using our website.",
  Visit: "Visit",
  Link: "Link",
  EditSocialMedia: "Edit social media",
};
