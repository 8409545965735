import axios from "axios";
import { ServerAPI, VERSION } from "../../global/Config";
import { HandleError } from "../../utils/error/Error";

const URL = `${ServerAPI}/${VERSION}/content`;

export const CreateContent = async (token, params) => {
  const context = `Content @CreateContent`;
  try {
    const result = await axios.post(`${URL}`, params, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (error) {
    HandleError(context, error);
  }
};

export const UpdateContent = async (token, id, params) => {
  const context = `Content @UpdateContent`;
  try {
    const result = await axios.put(`${URL}/${id}`, params, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (error) {
    HandleError(context, error);
  }
};

export const DeleteContent = async (token, id) => {
  const context = `Content @DeleteContent`;
  try {
    const result = await axios.delete(`${URL}/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (error) {
    HandleError(context, error);
  }
};
