import { isEmpty } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NotFound from "../global/NotFound";
import { selectorUser } from "../redux/store/selectors";
import ChatScreen from "../screens/home/ChatScreen";
import HomeScreen from "../screens/home/HomeScreen";
import TutorialScreen from "../screens/home/TutorialScreen";
import EmailOTPScreen from "../screens/landing/EmailOTPScreen";
import SignInScreen from "../screens/landing/SignInScreen";
import SignUpScreen from "../screens/landing/SignUpScreen";
import FavoriteScreen from "../screens/profile/FavoriteScreen";
import HelpScreen from "../screens/profile/HelpScreen";
import ProfileScreen from "../screens/profile/ProfileScreen";
import SettingsScreen from "../screens/profile/SettingsScreen";
import SearchScreen from "../screens/search/SearchScreen";

function LandingRoutes() {
  return (
    <Switch>
      <Route path="/signup" component={SignUpScreen} />
      <Route path="/emailotp" component={EmailOTPScreen} />
      <Route exact path="/" component={SignInScreen} />
      <Route component={SignInScreen} />
    </Switch>
  );
}
function HomeRoutes() {
  return (
    <Switch>
      <Route exact path="/" component={HomeScreen} />
      <Route exact path="/profile" component={ProfileScreen} />
      <Route exact path="/chat" component={ChatScreen} />
      <Route path="/profile/settings" component={SettingsScreen} />
      <Route path="/profile/favorite" component={FavoriteScreen} />
      <Route path="/profile/help" component={HelpScreen} />
      <Route path="/search" component={SearchScreen} />
      <Route path="/tutorial" component={TutorialScreen} />
      <Route component={NotFound} />
    </Switch>
  );
}

function Navigation(props) {
  const { user } = props;
  console.log({ user });
  return <Router>{isEmpty(user) ? LandingRoutes() : HomeRoutes()}</Router>;
}

const mapStateToProps = (state) => ({
  user: selectorUser(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
