import { Modal, Slide } from "@mui/material";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import i18n from "../../locale/I18";
import PropTypes from "prop-types";
import TextAreaInput from "../common/TextAreaInput";
import TextInput from "../common/TextInput";
import NoImage from "../../assets/image/home/no_img.jpg";
import CustomButton from "../common/CustomButton";
import { FaVideo } from "react-icons/fa";

ContentModal.propTypes = {
  active: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default function ContentModal(props) {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [pickedFile, setPickedFile] = useState(null);
  const [imagePath, setImagePath] = useState("");
  const [audioPath, setAudioPath] = useState("");
  const [audioName, setAudioName] = useState("");
  const [audioSize, setAudioSize] = useState("");
  const [videoPath, setVideoPath] = useState("");
  const [videoDuration, setVideoDuration] = useState("");
  const [index, setIndex] = useState(null);
  const [id, setId] = useState(null);

  useEffect(() => {
    if (!isEmpty(props.data)) {
      setTitle(props.data.title);
      setId(props.data.id);
      setIndex(props.data.index);
      switch (props.type) {
        case "text":
          setText(props.data.data);
          break;
        case "image":
          setImagePath(props.data.data);
          break;
        case "video":
          setVideoPath(props.data.data);
          break;
        case "audio":
          setAudioPath(props.data.data);
          setAudioName(props.data.data.split("/").pop());
          break;
        default:
          break;
      }
    }
  }, [props.data]);

  const confirmDiscard = () => {
    global.showConfirmAlert({
      title: i18n.t("Warning"),
      message: i18n.t("SureDiscardChanges"),
      confirmText: i18n.t("Discard"),
      onConfirm: () => onClose(),
    });
  };

  const setInitialValues = () => {
    setTimeout(() => {
      setTitle("");
      setText("");
      setImagePath("");
      setVideoPath("");
      setVideoDuration("");
      setAudioPath("");
      setAudioSize(null);
      setAudioName(null);
    }, 500);
  };

  const onClose = () => {
    setInitialValues();
    props.onClose();
  };

  let discard = false;
  let buttonDisable = true;

  const onAdd = () => {
    let data = {
      ...props.data,
      title,
      index,
      id,
      file: { file: pickedFile.file, mime: pickedFile.mime },
    };
    switch (props.type) {
      case "text":
        data = { ...data, type: "text", data: text };
        break;
      case "image":
        data = {
          ...data,
          type: "image",
          data: imagePath,
        };

        break;
      case "video":
        data = {
          ...data,
          type: "video",
          data: videoPath,
        };
        break;
      case "audio":
        data = {
          ...data,
          type: "audio",
          data: audioPath,
        };
        break;
      default:
        break;
    }
    if (!isEmpty(props.data)) {
      props.onEdit(data);
    } else {
      props.onAdd(data);
    }
    onClose();
  };

  const onChooseAudio = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.accept = "audio/mp3, audio/wave";
    input.click();
    input.addEventListener("input", setAudio);
  };
  const setAudio = (e) => {
    const pickedAudio = e.target.files[0];
    const url = URL.createObjectURL(pickedAudio);
    setAudioPath(url);
    setAudioSize(pickedAudio.size);
    setAudioName(pickedAudio.name);
    setPickedFile({
      path: url,
      file: pickedAudio,
      mime: pickedAudio.type,
    });
  };
  const onChooseImage = async () => {
    var input = document.createElement("input");
    input.type = "file";
    input.accept = "image/png, image/jpeg, image/jpg";
    input.click();
    input.addEventListener("input", setImage);
  };

  const setImage = (e) => {
    const pickedImage = e.target.files[0];
    const url = URL.createObjectURL(pickedImage);
    console.log({ url });
    setImagePath(url);
    setPickedFile({
      path: url,
      file: pickedImage,
      mime: pickedImage.type,
    });
  };
  const onChooseVideo = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.accept = "video/mp4, video/mkv";
    input.click();
    input.addEventListener("input", setVideo);
  };

  const setVideo = (e) => {
    const pickedVideo = e.target.files[0];
    const url = URL.createObjectURL(pickedVideo);
    setVideoPath(url);
    setPickedFile({
      path: url,
      file: pickedVideo,
      mime: pickedVideo.type,
    });
  };

  const getVideoDuration = (duration) => {
    const durationMinutes = Math.floor(duration / 60)
      .toString()
      .padStart(2, "0");
    const durationSeconds = Math.floor(duration - durationMinutes * 60)
      .toString()
      .padStart(2, "0");
    if (videoDuration == "") {
      setVideoDuration(`${durationMinutes}:${durationSeconds}`);
      console.log({ durationMinutes, durationSeconds });
    }
  };

  const renderContent = () => {
    switch (props.type) {
      case "audio":
        if (isEmpty(props.data)) {
          if (title != "" && audioPath != "") {
            buttonDisable = false;
          }
          if (title != "" || audioPath != "") {
            discard = true;
          }
        } else {
          if (title != props.data.title || audioPath != props.data.data) {
            buttonDisable = false;
            discard = true;
          }
        }

        let aud = audioPath ? audioName : "";
        let audSize = audioSize ? (audioSize / 1024 / 1024).toFixed(1) : 0;

        return (
          <div className="p-4 flex flex-col items-center justify-between">
            <TextInput
              onTextChange={(text) => setTitle(text)}
              placeholder={i18n.t("Title")}
              value={title}
              size="full"
            />

            {aud !== "" && (
              <div className="w-full flex flex-col justify-between items-start">
                <p className="w-full text-left">{i18n.t("FileName")}</p>
                <div className="w-full h-10 flex justify-between items-center">
                  <p className="text-left text-gray-400 text-lg">{aud}</p>
                  {audioSize && (
                    <p className="text-lg text-gray-400">{audSize} MB</p>
                  )}
                </div>
              </div>
            )}

            <CustomButton onClick={() => onChooseAudio()} size="medium">
              <p>{i18n.t("ChooseAudio")}</p>
            </CustomButton>
          </div>
        );
      case "video":
        if (isEmpty(props.data)) {
          if (title != "" && videoPath != "") {
            buttonDisable = false;
          }
          if (title != "" || videoPath != "") {
            discard = true;
          }
        } else {
          if (title != props.data.title || videoPath != props.data.data) {
            buttonDisable = false;
            discard = true;
          }
        }
        let vid = videoPath ? videoPath : null;
        return (
          <div className="p-4 flex flex-col items-center justify-between h-full">
            <TextInput
              onTextChange={(title) => setTitle(title)}
              placeholder={i18n.t("Title")}
              value={title}
              size="full"
            />

            <div
              className={`w-full max-w-md h-72 rounded-lg ${
                vid ? "bg-black" : "bg-gray-300"
              } flex flex-col items-center justify-center`}
            >
              {vid ? (
                <video
                  id="video"
                  className="w-full h-full rounded-lg"
                  controls
                  onLoadedMetadata={(data) => {
                    getVideoDuration(document.getElementById("video").duration);
                  }}
                >
                  <source src={vid} />
                </video>
              ) : (
                <FaVideo className="text-gray-400 text-4xl" />
              )}
            </div>

            <CustomButton onClick={() => onChooseVideo()} size="medium">
              <p>{i18n.t("ChooseVideo")}</p>
            </CustomButton>
          </div>
        );
      case "image":
        if (isEmpty(props.data)) {
          if (title != "" && imagePath != "") {
            buttonDisable = false;
          }
          if (title != "" || imagePath != "") {
            discard = true;
          }
        } else {
          if (title != props.data.title || imagePath != props.data.data) {
            buttonDisable = false;
            discard = true;
          }
        }
        let img = imagePath ? imagePath : NoImage;
        return (
          <div className="p-4 flex flex-col items-center justify-between h-full">
            <TextInput
              onTextChange={(title) => setTitle(title)}
              placeholder={i18n.t("Title")}
              value={title}
              size={"full"}
            />

            <img src={img} className="w-full max-w-md h-72 rounded-lg" />
            <CustomButton onClick={() => onChooseImage()} size="medium">
              <p>{i18n.t("ChooseImage")}</p>
            </CustomButton>
          </div>
        );
      case "text":
        if (isEmpty(props.data)) {
          if (title != "" && text != "") {
            buttonDisable = false;
          }
          if (title != "" || text != "") {
            discard = true;
          }
        } else {
          if (title != props.data.title || text != props.data.data) {
            buttonDisable = false;
            discard = true;
          }
        }
        return (
          <div className="p-4 ">
            <TextInput
              onTextChange={(title) => setTitle(title)}
              placeholder={i18n.t("Title")}
              value={title}
              size={"full"}
            />

            <TextAreaInput
              onTextChange={(text) => setText(text)}
              placeholder={i18n.t("TextPlaceholder")}
              value={text}
            />
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <Modal
      open={props.active}
      onClose={() => {
        if (discard) return confirmDiscard();
        else return onClose();
      }}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
      }}
    >
      <Slide direction="up" in={props.active} mountOnEnter unmountOnExit>
        <div className="bg-white w-full max-w-2xl h-5/6 rounded-t-lg border-none outline-none shadow-lg flex flex-col">
          <div className="w-full h-12 p-3 border-b-2 border-gray-600 flex items-center justify-center rounded-t-lg">
            <p>
              {i18n.t("Upload")} {props.type}
            </p>
          </div>
          <div className="h-full">{renderContent()}</div>
          <div className="p-4 flex justify-self-end">
            <CustomButton
              disabled={buttonDisable}
              onClick={onAdd}
              size="full"
              style="primary-light"
            >
              <p>{isEmpty(props.data) ? i18n.t("Add") : i18n.t("Edit")}</p>
            </CustomButton>
          </div>
        </div>
      </Slide>
    </Modal>
  );
}
