import React from "react";
import PropTypes from "prop-types";

TextAreaInput.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onTextChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium", "large", "full"]),
  className: PropTypes.string,
};

export default function TextAreaInput(props) {
  const {
    value = "",
    placeholder = "placeholder",
    onTextChange = () => {},
    disabled = false,
    size = "full",
    className,
  } = props;
  return (
    <textarea
      className={`${className} txt-area-inpt-${size} bg-gray-300 border-none outline-none rounded-lg p-2 text-black font-sans mb-2 mt-2`}
      inputMode="text"
      value={value}
      onChange={(e) => {
        onTextChange(e.target.value);
      }}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
}
