import axios from "axios";
import { ServerAPI, VERSION } from "../../global/Config";
import { HandleError } from "../../utils/error/Error";

const URL = `${ServerAPI}/${VERSION}/user`;

export const GetTeacher = async (token) => {
  const context = "User @GetTeacher";
  try {
    const result = await axios.get(`${URL}/getteacher`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (error) {
    HandleError(context, error);
  }
};

export const UpdateUser = async (token, params) => {
  const context = "User @UpdateUser";
  try {
    const result = await axios.put(`${URL}/`, params, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (error) {
    HandleError(context, error);
  }
};
