import React from "react";
import Navbar from "./Navbar";

export default function BaseView(props) {
  return (
    <div className="w-full bg-gray-100 min-h-full flex flex-col items-center">
      <Navbar />
      <div className="w-full max-w-5xl flex flex-col items-center pb-14 md:pt-14 md:pb-0 ">
        {props.children}
      </div>
    </div>
  );
}
