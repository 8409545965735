import {
  GET_CHAT_MESSAGES_RESPONSE,
  GET_CHAT_THREADS_RESPONSE,
} from '../constant/types';

const initialState = {
  chatThreads: [],
  chatMessages: [],
};

export default (state = initialState, action = {}) => {
  const {payload, type} = action;
  switch (type) {
    // Manage setIsLoadingRequest action to change loading status into store
    case GET_CHAT_THREADS_RESPONSE:
      return {
        ...state,
        chatThreads: [...payload.params],
      };
    case GET_CHAT_MESSAGES_RESPONSE:
      return {
        ...state,
        chatMessages: [...payload.params],
      };

    default:
      return state;
  }
};
