import axios from "axios";
import { ServerAPI, VERSION } from "../../global/Config";
import { HandleError } from "../../utils/error/Error";

const URL = `${ServerAPI}/${VERSION}/publicdata`;

export const GetPublicData = async () => {
  const context = "PublicData @GetPublicData";
  try {
    const response = await axios.get(`${URL}`);
    console.log({ response });
    return response.data;
  } catch (error) {
    HandleError(context, error);
  }
};

export const CreatePublicData = async (token, params) => {
  const context = "PublicData @SetPublicData";
  try {
    const response = await axios.post(`${URL}`, params, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    HandleError(context, error);
  }
};
export const UpdatePublicData = async (token, key, params) => {
  const context = "PublicData @UpdatePublicData";
  try {
    const response = await axios.put(`${URL}/${key}`, params, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    HandleError(context, error);
  }
};
