import {
  GET_TEACHER_DATA_RESPONSE,
  GET_USER_DATA_RESPONSE,
  SET_IS_TEACHER_OR_ADMIN,
} from '../constant/types';

const initialState = {
  user: {},
  teacher: {},
  isTeacherOrAdmin: false,
};

export default (state = initialState, action = {}) => {
  const {payload, type} = action;
  switch (type) {
    // Manage setIsLoadingRequest action to change loading status into store
    case GET_USER_DATA_RESPONSE:
      return {
        ...state,
        user: {...payload.params},
      };
    case GET_TEACHER_DATA_RESPONSE:
      return {
        ...state,
        teacher: {...payload.params},
      };
    case SET_IS_TEACHER_OR_ADMIN:
      return {
        ...state,
        isTeacherOrAdmin: payload.params,
      };

    default:
      return state;
  }
};
