import { get, isEmpty } from "lodash";

import { call, put, select } from "redux-saga/effects";
import { CreateComplete, GetMyCompletes } from "../../../API/complete/Complete";
import { getCompleteResponse } from "../../action/complete.action";
import { selectorCompletes, selectorUser } from "../../store/selectors";

export function* getComplete() {
  const user = yield select(selectorUser);
  const completes = yield call(GetMyCompletes, user?.accessToken);
  yield put(getCompleteResponse(completes));
}

export function* setComplete(params) {
  const user = yield select(selectorUser);
  params = get(params, "payload.params");
  let completes = yield select(selectorCompletes);
  const result = yield call(CreateComplete, user?.accessToken, params);
  if (!isEmpty(result)) {
    const newCompletes = [...completes, result];
    yield put(getCompleteResponse(newCompletes));
  }
}
