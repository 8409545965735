import React, { useState } from "react";
import BaseView from "../../components/common/BaseView";
import TopBackground from "../../assets/image/profile/TopBackGround.png";
import i18n from "../../locale/I18";
import { FiChevronRight } from "react-icons/fi";
import { setPublicDataRequest } from "../../redux/action/general.action";
import { connect } from "react-redux";
import {
  selectorPublicData,
  selectorTeacher,
  selectorUser,
} from "../../redux/store/selectors";
import VideoBackgroundModal from "../../components/profile/VideoBackgroundModal";
import { UploadFile } from "../../API/storage/Storage";
import { isEmpty } from "lodash";

function SettingsScreen(props) {
  const { user, setPublicData } = props;
  const [activeVideoBgModal, setActiveVideoBgModal] = useState(false);
  const [activeSocialsModal, setActiveSocialsModal] = useState(false);
  const onClickSocial = () => {
    setActiveSocialsModal(true);
  };

  const onSaveSocials = (video) => {
    const { setPublicData } = props;
    const data = {
      videoBackground: video,
    };
    setPublicData(data);
  };

  const onClickVideo = () => {
    setActiveVideoBgModal(true);
  };
  const onSaveVideoBackground = async ({ file }) => {
    if (file != null) {
      global.showToast({
        duration: 3000,
        message: "StartUploading",
        variant: "info",
      });
      const response = await UploadFile(user?.accessToken, {
        file,
        ref: `publicData/videoBackground`,
      });
      if (!isEmpty(response)) {
        setPublicData({
          key: "videoBackground",
          value: response.downloadUrl,
        });
        global.showToast({
          duration: 3000,
          message: "BackgroundVideoUpdated",
          variant: "success",
        });
      }
    }
  };
  const buttons = [
    { title: "SettingsChangeIntroductionVideo", onClick: onClickVideo },
    // { title: "SettingsEditSocials", onClick: onClickSocial },
  ];

  const { publicData, teacher } = props;
  console.log({ publicData, teacher });

  return (
    <BaseView>
      <div className="w-full h-full flex-col flex items-center justify-center relative">
        <div className="w-full h-64 relative flex flex-col items-center">
          <img src={TopBackground} className="w-full h-full" />
        </div>
        <div className="bg-white rounded-lg w-11/12 absolute top-6 max-w-md p-2 flex flex-col mb-6">
          {buttons.map((btn, index) => {
            return (
              <div
                onClick={btn.onClick}
                className={`flex items-center w-full relative cursor-pointer  py-3 px-1 border-gray-200 ${
                  buttons.length == index + 1 ? "border-b-0" : "border-b-2"
                }`}
              >
                <p>{i18n.t(btn.title)}</p>
                <FiChevronRight className="cursor-pointer absolute right-1" />
              </div>
            );
          })}
        </div>
      </div>

      <VideoBackgroundModal
        video={publicData.videoBackground || ""}
        active={activeVideoBgModal}
        onAdd={(video) => onSaveVideoBackground(video)}
        onClose={() => setActiveVideoBgModal(false)}
      />
      {/* 
      <SocailModal
        socials={teacher.socialMedia}
        active={activeSocialsModal}
        onAdd={socials => onSaveSocials(socials)}
        onClose={() => setActiveSocialsModal(false)}
      /> */}
    </BaseView>
  );
}

const mapStateToProps = (state) => ({
  publicData: selectorPublicData(state),
  teacher: selectorTeacher(state),
  user: selectorUser(state),
});

const mapDispatchToProps = {
  setPublicData: setPublicDataRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsScreen);
