import I18n from "i18n-js";
import en from "./en";

I18n.translations = {
  default: en,
  en,
};
I18n.fallbacks = true;

const i18n = I18n;
export default i18n;
