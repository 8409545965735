import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import BaseView from "../../components/common/BaseView";
import i18n from "../../locale/I18";
import {
  createContentRequest,
  deleteContentRequest,
  updateContentRequest,
} from "../../redux/action/content.action";
import {
  createFavoriteRequest,
  deleteFavoriteRequest,
} from "../../redux/action/favorite.action";
import {
  selectorContentAssemblerKey,
  selectorFavorites,
  selectorIsTeacherOrAdmin,
  selectorTeacher,
} from "../../redux/store/selectors";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import ContentAssembler from "../../components/home/ContentAssembler";
import CustomButton from "../../components/common/CustomButton";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import ContentModal from "../../components/home/ContentModal";
import ReviewModal from "../../components/home/ReviewModal";
import { FaVideo, FaImage } from "react-icons/fa";
import { AiFillAudio } from "react-icons/ai";
import { MdTextFields } from "react-icons/md";
import Colors from "../../utils/style/Colors";

function TutorialScreen(props) {
  const {
    isTeacherOrAdmin,
    route,
    favorites,
    createContent,
    updateContent,
    deleteContent,
    contentAssemblerKey,
    createFavorite,
    deleteFavorite,
  } = props;
  const location = useLocation();
  const history = useHistory();

  const data = get(location, "state.params.data");
  const isComplete = get(location, "state.params.isComplete");
  const [contentModal, setContentModal] = useState({
    type: "text",
    active: false,
    data: {},
  });
  const [reviewModal, setReviewModal] = useState(false);
  const [favorite, setFavorite] = useState(false);
  const [favoriteId, setFavoriteId] = useState(null);
  const [modalComponent, setModalComponent] = useState({
    active: false,
    title: null,
    data: null,
    modalContent: null,
  });

  useEffect(() => {
    return () => {};
  }, []);

  const onPressOpenModal = (data) => {
    setModalComponent({
      active: true,
      data: data?.video,
      title: data?.title,
      // modalContent: <VideoPlayer link={data?.video} />,
    });
  };

  const onPressCloseModal = () => {
    setModalComponent({
      active: false,
      title: null,
      data: null,
      modalContent: null,
    });
  };

  const confirmDeleteContent = (content, index) => {
    global.showConfirmAlert({
      title: i18n.t("Warning"),
      message: i18n.t("SureDeleteContent"),
      confirm: i18n.t("SureDeleteContentYes"),
      onConfirm: () => onDeleteContent(content, index),
    });
  };

  const onDeleteContent = (content, index) => {
    deleteContent({ index, content, categoryId: data.id });
  };

  const onEditContent = (item, index) => {
    setContentModal({
      active: true,
      type: item.type,
      data: { ...item, index },
    });
  };

  const onClickComplete = (params) => {
    global.showConfirmAlert({
      title: i18n.t("Warning"),
      message: i18n.t("AreYouSureToExit"),
      confirm: i18n.t("AreYouSureToExitYes"),
      onConfirm: () => onComplete(params),
    });
  };

  const onComplete = (params) => {
    history.push("review", { params: { data: params } });
  };

  const teacherFABOnButtons = (type) => {
    switch (type) {
      case "audio":
        setContentModal({ active: true, type: "audio", data: {} });
        break;
      case "video":
        setContentModal({ active: true, type: "video", data: {} });
        break;
      case "image":
        setContentModal({ active: true, type: "image", data: {} });
        break;
      case "text":
        setContentModal({ active: true, type: "text", data: {} });
        break;
      default:
        break;
    }
  };
  const onAddContent = (item) => {
    item.categoryId = data.id;
    createContent(item);
  };

  const editContent = (item) => {
    item.categoryId = data.id;
    updateContent(item);
  };

  const speedDialActions = [
    { icon: <FaVideo />, name: "Video", type: "video" },
    { icon: <FaImage />, name: "Image", type: "image" },
    { icon: <AiFillAudio />, name: "Audio", type: "audio" },
    { icon: <MdTextFields />, name: "Text", type: "text" },
  ];

  return (
    <BaseView>
      <div className="h-full w-full flex flex-col overflow-x-hidden">
        <div className="w-full flex justify-between items-center mb-8 h-14 p-4 shadow-sm  bg-white">
          <p className="text-xl font-sans font-bold text-black">
            {data?.title}
          </p>
          {/* <div
            onClick={() => {
              toggleFavorite();
            }}
            className="cursor-pointer"
          >
            {favorite ? (
              <FaHeart className="text-xl " />
            ) : (
              <FaRegHeart className="text-xl " />
            )}
          </div> */}
        </div>
        <div className="p-4 pb-12">
          {data.contents && (
            <ContentAssembler
              admin={isTeacherOrAdmin}
              key={contentAssemblerKey}
              data={data?.contents}
              onDeleteContent={(content, index) => {
                confirmDeleteContent(content, index);
              }}
              onEditContent={(item, index) => {
                onEditContent(item, index);
              }}
            />
          )}

          {!isComplete && !isTeacherOrAdmin && (
            <CustomButton
              onClick={() => {
                setReviewModal(true);
              }}
              className="mb-4 mt-8"
              size="full"
              style="primary-light"
            >
              <p>{i18n.t("SwipetoComplete")}</p>
            </CustomButton>
          )}

          {isTeacherOrAdmin && (
            <SpeedDial
              FabProps={{ style: { backgroundColor: "#000" } }}
              ariaLabel="SpeedDial basic example"
              className="fixed bottom-20 md:bottom-8 right-8"
              icon={<SpeedDialIcon />}
            >
              {speedDialActions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  FabProps={{ style: { backgroundColor: Colors.primaryLight } }}
                  onClick={() => teacherFABOnButtons(action.type)}
                />
              ))}
            </SpeedDial>
          )}
        </div>

        <ContentModal
          active={contentModal.active}
          type={contentModal.type}
          data={contentModal.data}
          onClose={() => {
            setContentModal({ active: false, type: "", data: {} });
          }}
          onAdd={(data) => {
            onAddContent(data);
          }}
          onEdit={(item) => {
            editContent(item);
          }}
        />

        <ReviewModal
          active={reviewModal}
          categoryId={data.id}
          onClose={() => {
            setReviewModal(false);
          }}
          onSubmit={(rating) => onComplete(rating)}
        />
      </div>
    </BaseView>
  );
}

const mapStateToProps = (state) => ({
  favorites: selectorFavorites(state),
  teacher: selectorTeacher(state),
  isTeacherOrAdmin: selectorIsTeacherOrAdmin(state),
  contentAssemblerKey: selectorContentAssemblerKey(state),
});

const mapDispatchToProps = {
  createContent: createContentRequest,
  updateContent: updateContentRequest,
  deleteContent: deleteContentRequest,
  createFavorite: createFavoriteRequest,
  deleteFavorite: deleteFavoriteRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(TutorialScreen);
