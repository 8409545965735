import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { UploadFile } from "../../API/storage/Storage";
import i18n from "../../locale/I18";
import { setUserDataRequest } from "../../redux/action/user.action";
import { selectorUser } from "../../redux/store/selectors";
import { RandomInt } from "../../utils/js";
import Avatar from "../common/Avatar";
import CustomButton from "../common/CustomButton";
import TextInput from "../common/TextInput";

function PersonalInfoTab(props) {
  const { user, updateProfile, onClose } = props;
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [instagram, setInstagram] = useState("");
  const [pickedFile, setPickedFile] = useState(null);
  let names = user?.userData?.fullName?.split(" ") || [];
  let namesExcFirstName = names?.slice(1, names.length);

  console.log({ names });

  useEffect(() => {
    if (!isEmpty(user)) {
      setFirstName(names[0]);
      setLastName(namesExcFirstName.join(" "));
      setInstagram(user?.userData?.instagram);
      names = user?.userData?.fullName?.split(" ");
      namesExcFirstName = names.slice(1, names.length);
    }
  }, [user]);

  const onUpdate = async () => {
    setLoading(true);

    const updateData = {
      instagram,
      fullName: `${firstName} ${lastName}`,
    };
    if (!isEmpty(pickedFile)) {
      const ref = `profileImages/${user?.userData?.id}`;
      const photo = await UploadFile(user?.accessToken, {
        file: pickedFile.file,
        ref,
        mime: pickedFile.mime,
      });
      console.log({ photo });
      if (!isEmpty(photo)) updateData.photo = photo.downloadUrl;
    }

    await updateProfile(updateData);
    onClose();
    setLoading(false);
  };
  console.log({ pickedFile });
  const onChooseImage = async () => {
    var input = document.createElement("input");
    input.type = "file";
    input.accept = "image/png, image/jpeg, image/jpg";
    input.click();
    input.addEventListener("input", chooseImage);
  };

  const chooseImage = async (e) => {
    const file = e.target.files[0];
    setPickedFile({
      file,
      path: URL.createObjectURL(file),
      mime: file.type,
    });
  };

  let buttonDisable = true;

  if (
    firstName?.trim() != names[0] ||
    lastName?.trim() != namesExcFirstName.join(" ") ||
    instagram?.trim() != user?.userData?.instagram ||
    pickedFile != null
  )
    buttonDisable = false;

  const photo = pickedFile ? pickedFile.path : user?.userData?.photo;

  return (
    <div className="w-full flex flex-col items-center">
      <Avatar src={photo} size={"5rem"} rounded onChange={onChooseImage} />
      <div className="w-full">
        <p>{i18n.t("FirstName")}</p>
        <TextInput
          placeholder=""
          type={"text"}
          size={"full"}
          value={firstName}
          onTextChange={(text) => {
            setFirstName(text);
          }}
        />
      </div>
      <div className="w-full">
        <p>{i18n.t("LastName")}</p>
        <TextInput
          placeholder=""
          type={"text"}
          size={"full"}
          value={lastName}
          onTextChange={(text) => {
            setLastName(text);
          }}
        />
      </div>

      <div className="w-full">
        <p>{i18n.t("Instagram")}</p>
        <TextInput
          placeholder=""
          type={"text"}
          size={"full"}
          value={instagram}
          onTextChange={(text) => {
            setInstagram(text);
          }}
        />
      </div>

      <CustomButton
        loading={loading}
        disabled={buttonDisable}
        onClick={onUpdate}
        className="mb-4 mt-5"
        size="full"
        style="primary-light"
      >
        <p>{i18n.t("Update")}</p>
      </CustomButton>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: selectorUser(state),
});
const mapDispatchToProps = {
  updateProfile: setUserDataRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfoTab);
