import React, { useState } from "react";
import i18n from "../../locale/I18";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { FcGoogle } from "react-icons/fc";
import TextInput from "../../components/common/TextInput";
import CustomButton from "../../components/common/CustomButton";
import RoundButton from "../../components/common/RoundButton";
import { useLocation } from "react-router";

function SignUpScreen(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();

  const onSignUp = () => {};
  const onGoogleSignIn = () => {};

  let disableButton = true;
  if (firstName != "" && lastName != "" && email != "" && password != "" && password.length >= 8)
    disableButton = false;
  return (
    <div className="primary-light h-full flex justify-center items-center">
      <div className="rounded-lg max-w-4xl relative bg-white shadow-lg flex flex-col items-center w-10/12 p-4">
        <p className="text-black text-left w-full text-2xl mb-6">{i18n.t("CreateNewAccount")}</p>
        <TextInput
          placeholder={i18n.t("FirstName")}
          type={"text"}
          size={"full"}
          value={firstName}
          onTextChange={(text) => {
            setFirstName(text);
          }}
        />
        <TextInput
          placeholder={i18n.t("LastName")}
          type={"text"}
          size={"full"}
          value={lastName}
          onTextChange={(text) => {
            setLastName(text);
          }}
        />
        <TextInput
          placeholder={i18n.t("Email")}
          type={"email"}
          size={"full"}
          value={email}
          onTextChange={(text) => {
            setEmail(text);
          }}
        />
        <TextInput
          placeholder={i18n.t("Password")}
          type={showPassword ? "text" : "password"}
          size={"full"}
          rightIcon={
            showPassword ? <FiEye className="text-black" /> : <FiEyeOff className="text-black" />
          }
          rightIconOnClick={() => {
            setShowPassword(!showPassword);
          }}
          value={password}
          onTextChange={(text) => {
            setPassword(text);
          }}
        />
        <CustomButton
          disabled={disableButton}
          onClick={onSignUp}
          className="mb-4 mt-8"
          size="full"
          style="primary-light"
        >
          <p>{i18n.t("SignUp")}</p>
        </CustomButton>

        <div className="w-full max-w-xl mt-10 mb-10 h-full flex flex-col items-center">
          <div className="flex w-full justify-between items-center">
            <div className="w-full bg-gray-200 h-px" />
            <p className="text-gray-500 w-full text-center mr-3 ml-3">{i18n.t("OrConnectWith")}</p>
            <div className="w-full bg-gray-200 h-px" />
          </div>
          <RoundButton
            onClick={onGoogleSignIn}
            className="mt-auto mb-auto"
            size="medium"
            style="white"
          >
            <FcGoogle className="w-full h-full" />
          </RoundButton>
        </div>
      </div>
    </div>
  );
}

export default SignUpScreen;
