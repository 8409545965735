import { Modal, Slide } from "@mui/material";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import i18n from "../../locale/I18";
import PropTypes from "prop-types";
import CustomButton from "../common/CustomButton";

VideoBackgroundModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  video: PropTypes.string.isRequired,
};

export default function VideoBackgroundModal(props) {
  const { video, onClose, onAdd, active } = props;
  console.log({ props });

  const [videoBackground, setVideoBackground] = useState("");
  const [pickedFile, setPickedFile] = useState(null);

  useEffect(() => {
    if (!isEmpty(video)) {
      setVideoBackground(video);
    }
  }, [video]);

  const onChooseVideo = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.accept = "video/mp4, video/mkv";
    input.click();
    input.addEventListener("input", setVideo);
  };

  const setVideo = (e) => {
    const pickedVideo = e.target.files[0];
    const url = URL.createObjectURL(pickedVideo);
    setPickedFile({ file: pickedVideo, path: url, mime: pickedVideo.type });
  };

  const close = () => {
    setVideoBackground("");
    setPickedFile(null);
    onClose();
  };

  const onSubmit = () => {
    onAdd(pickedFile);
    close();
  };

  const confirmDiscard = () => {
    global.showConfirmAlert({
      title: i18n.t("Warning"),
      message: i18n.t("SureDiscardChanges"),
      confirmText: i18n.t("Discard"),
      onConfirm: () => close(),
    });
  };

  let buttonDisable = true;
  let discard = false;
  if (!isEmpty(pickedFile)) {
    buttonDisable = false;
    discard = true;
  }

  console.log({ pickedFile, video });

  const vid = !isEmpty(pickedFile) ? pickedFile.path : video ? video : "";

  return (
    <Modal
      open={active}
      onClose={() => {
        if (discard) return confirmDiscard();
        else return close();
      }}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
      }}
    >
      <Slide direction="up" in={props.active} mountOnEnter unmountOnExit>
        <div className="bg-white w-full max-w-2xl h-5/6 rounded-t-lg border-none outline-none shadow-lg flex flex-col">
          <div className="w-full h-12 p-3 border-b-2 border-gray-600 flex items-center justify-center rounded-t-lg">
            <p>{i18n.t("SettingsChangeIntroductionVideo")}</p>
          </div>
          <div className="h-full p-2">
            <div className="flex h-full w-full flex-col items-center justify-between">
              <video
                key={Math.random()}
                id="video"
                className="w-full max-w-xs h-full max-h-96 bg-black rounded-lg"
                autoPlay
                loop
              >
                <source src={`${vid}?bust=${Math.random()}`} />
              </video>
              <CustomButton onClick={() => onChooseVideo()} size="medium">
                <p>{i18n.t("ChooseVideo")}</p>
              </CustomButton>
            </div>
          </div>
          <div className="p-4 flex justify-self-end">
            <CustomButton
              onClick={() => onSubmit()}
              size="full"
              style="primary-light"
              disabled={buttonDisable}
            >
              <p>{isEmpty(video) ? i18n.t("Add") : i18n.t("Update")}</p>
            </CustomButton>
          </div>
        </div>
      </Slide>
    </Modal>
  );
}
