import { Modal, Slide } from "@mui/material";
import React, { useState } from "react";

export default function CustomMenu() {
  const [active, setActive] = useState(false);
  const [items, setItems] = useState([]);
  const [menuTitle, setMenuTitle] = useState("");

  const showMenu = (params) => {
    setItems(params.items);
    setMenuTitle(params.menuTitle);
    setActive(true);
  };

  global.showMenu = showMenu;

  const onClose = () => {
    setActive(false);
    setTimeout(() => {
      setItems([]);
      setMenuTitle("");
    }, 500);
  };
  return (
    <Modal
      open={active}
      onClose={onClose}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Slide direction="up" in={active} mountOnEnter unmountOnExit>
        <div className="p-4 rounded-lg outline-none bg-white w-5/6 max-w-xs">
          <p className="text-black text-xl font-sans font-bold mb-4">
            {menuTitle}
          </p>
          {items.map((item) => {
            return (
              <div
                onClick={() => {
                  item.onClick();
                  onClose();
                }}
                className="w-full h-10 cursor-pointer flex items-center"
              >
                {item.title}
              </div>
            );
          })}
        </div>
      </Slide>
    </Modal>
  );
}
