export const CloneObject = (object) => {
  return JSON.parse(JSON.stringify(object));
};

export const UndefineCleaner = (object) => {
  Object.keys(object).forEach(
    (key) => object[key] === undefined && delete object[key]
  );
  return object;
};

export const RandomInt = () => {
  return Math.floor(Math.random() * 1000) + 1;
};
