import {
  GET_COMPLETES_REQUEST,
  GET_COMPLETES_RESPONSE,
  SET_COMPLETE_REQUEST,
} from "../constant/types";

export const getCompleteRequest = (params) => ({
  type: GET_COMPLETES_REQUEST,
  payload: { params },
});

export const getCompleteResponse = (params) => ({
  type: GET_COMPLETES_RESPONSE,
  payload: { params },
});

export const setCompleteRequest = (params) => ({
  type: SET_COMPLETE_REQUEST,
  payload: { params },
});
