import React from "react";
import PropTypes from "prop-types";
import i18n from "../../locale/I18";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";

VideoComponent.propTypes = {
  /**ImageComponent video */
  video: PropTypes.string.isRequired,
  /**ImageComponent title */
  title: PropTypes.string.isRequired,
  /**ImageComponent cover image */
  cover: PropTypes.string.isRequired,
  /**ImageComponent time duration */
  time: PropTypes.string.isRequired,
  /**is admin or not */
  admin: PropTypes.bool,
  /**onDelete item function  */
  onDelete: PropTypes.bool,
  /**onEdit item function  */
  onEdit: PropTypes.bool,
};

export default function VideoComponent(props) {
  const { title, video, time, admin, onDelete, onEdit } = props;
  return (
    <div className="flex flex-col relative w-full mb-8">
      <div className="flex items-center justify-between">
        <p className="text-black  font-sans font-bold mb-1">{title}</p>
        {admin && (
          <div className="flex items-center justify-end">
            <AiFillEdit
              onClick={onEdit}
              className="text-lg mr-3 cursor-pointer"
            />
            <MdDelete onClick={onDelete} className="text-lg cursor-pointer" />
          </div>
        )}
      </div>
      <div className="flex items-center justify-center bg-black w-full h-80 rounded-lg">
        <video controls className="h-full">
          <source src={video} />
        </video>
      </div>
      {/* <p className="text-black font-sans">{`${i18n.t("TimeDuration")} : ${time}`}</p> */}
    </div>
  );
}
