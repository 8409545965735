import {
  GET_CHAT_MESSAGES_REQUEST,
  GET_CHAT_MESSAGES_RESPONSE,
  GET_CHAT_THREADS_REQUEST,
  GET_CHAT_THREADS_RESPONSE,
  SET_MESSAGE_REQUEST,
} from "../constant/types";

export const getChatMessagesRequest = (params) => ({
  type: GET_CHAT_MESSAGES_REQUEST,
  payload: { params },
});

export const getChatMessagesResponse = (params) => ({
  type: GET_CHAT_MESSAGES_RESPONSE,
  payload: { params },
});

export const getChatThreadsRequest = (params) => ({
  type: GET_CHAT_THREADS_REQUEST,
  payload: { params },
});

export const getChatThreadsResponse = (params) => ({
  type: GET_CHAT_THREADS_RESPONSE,
  payload: { params },
});

export const setMessageRequest = (params) => ({
  type: SET_MESSAGE_REQUEST,
  payload: { params },
});
