import i18n from "i18n-js";
import React, { useState } from "react";
import { connect } from "react-redux";
import { ChangePassword } from "../../API/auth/Auth";
import { selectorUser } from "../../redux/store/selectors";
import CustomButton from "../common/CustomButton";
import TextInput from "../common/TextInput";
import { FiEye, FiEyeOff } from "react-icons/fi";

function PasswordTab(props) {
  const { user, onClose } = props;
  const [newPass, setNewPass] = useState("");
  const [curPass, setCurPass] = useState("");
  const [showCurPass, setShowCurPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [loading, setLoading] = useState(false);

  const onChange = async () => {
    setLoading(true);
    const result = await ChangePassword(
      { currentPassword: curPass, newPassword: newPass },
      user?.accessToken
    );
    setLoading(false);
    if (result == "done") {
      global.showToast({
        duration: 3000,
        message: "PasswordChanged",
        variant: "success",
      });
    } else if (result == "current_password_is_not_match") {
      return global.showToast({
        duration: 3000,
        message: "CurrentPasswordNotMatch",
        variant: "error",
      });
    } else {
      global.showToast({
        duration: 3000,
        message: "SomeThingWentWrong",
        variant: "error",
      });
    }
    onClose();
  };

  let buttonDisable = true;
  if (curPass != "" && newPass != "" && newPass.length >= 8)
    buttonDisable = false;
  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-full">
        <p>{i18n.t("CurrentPassword")}</p>
        <TextInput
          placeholder=""
          type={showCurPass ? "text" : "password"}
          size={"full"}
          rightIcon={
            showCurPass ? (
              <FiEye className="text-black" />
            ) : (
              <FiEyeOff className="text-black" />
            )
          }
          rightIconOnClick={() => {
            setShowCurPass(!showCurPass);
          }}
          value={curPass}
          onTextChange={(text) => {
            setCurPass(text);
          }}
        />
      </div>
      <div className="w-full">
        <p>{i18n.t("NewPassword")}</p>
        <TextInput
          placeholder=""
          type={showNewPass ? "text" : "password"}
          size={"full"}
          rightIcon={
            showNewPass ? (
              <FiEye className="text-black" />
            ) : (
              <FiEyeOff className="text-black" />
            )
          }
          rightIconOnClick={() => {
            setShowNewPass(!showNewPass);
          }}
          value={newPass}
          onTextChange={(text) => {
            setNewPass(text);
          }}
        />
      </div>

      <CustomButton
        loading={loading}
        disabled={buttonDisable}
        onClick={onChange}
        className="mb-4 mt-5"
        size="full"
        style="primary-light"
      >
        <p>{i18n.t("Change")}</p>
      </CustomButton>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: selectorUser(state),
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordTab);
