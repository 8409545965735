import initial from "../reducer/initial.reducer";
import general from "./general.reducer";
import user from "./user.reducer";
import classes from "./classes.reducer";
import blog from "./blog.reducer";
import workshops from "./workshops.reducer";
import favorite from "./favorite.reducer";
import complete from "./complete.reducer";
import chat from "./chat.reducer";
import currentUser from "./currentUser.reducer";
import payment from "./payment.reducer";
import category from "./category.reducer";

const reducers = {
  initial,
  general,
  user,
  classes,
  blog,
  workshops,
  favorite,
  complete,
  chat,
  payment,
  currentUser,
  category,
};

export default reducers;
