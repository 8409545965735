import {
  CONTENT_ASSEMBLER_KEY,
  GET_PUBLIC_DATA_RESPONSE,
} from '../constant/types';

const initialState = {
  contentAssemblerKey: 0,
  publicData: {},
};

export default (state = initialState, action = {}) => {
  const {payload, type} = action;
  switch (type) {
    // Manage setIsLoadingRequest action to change loading status into store
    case CONTENT_ASSEMBLER_KEY:
      return {
        ...state,
        contentAssemblerKey: payload.params,
      };

    case GET_PUBLIC_DATA_RESPONSE:
      return {
        ...state,
        publicData: {...payload.params},
      };

    default:
      return state;
  }
};
